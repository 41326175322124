import React from "react";
import TextField from "@material-ui/core/TextField";
import K from "./../K";
import axios from "axios";
import styled from "styled-components";
import { toast } from "react-toastify";
import Box from "@material-ui/core/Box";
import { Tick } from "react-crude-animated-tick";
import emailjs from "emailjs-com";
import creds from "./../credentials.json";

const Form = styled.form`
    .grid-input {
        width: 100%;
        /* margin: 10px 0px; */
    }

    button {
        background-color: ${K.Theme.secondary2};
        color: ${K.Theme.primary2};
        display: flex;
        justify-content: center;
        /* width: calc(100%); */
        border: none;
        padding: 15px 10px;
        border-radius: 5px;
        font-size: 1rem;
        transition: all 0.3s linear;
        bottom: 0;
        /* position: absolute; */
        /* margin: 5px; */
        width: 100%;

        :hover {
            background-color: ${K.Theme.primary2};
            color: ${K.Theme.secondary2};
            cursor: pointer;
        }
    }
`;

export default function ContactForm() {
    var [data] = React.useState({
        Name: "",
        Email: "",
        "Your Message": "",
    });

    var [isValid, setIsValid] = React.useState(0);

    function sendEmail() {
        var toSend = { ...data };
        toSend["message"] = data["Your Message"];
        emailjs
            .send(creds.service_id, creds.template_id, toSend, creds.user_id)
            .then(
                (result) => {
                    setIsValid(2);
                },
                (error) => {
                    setIsValid(0);
                }
            );
    }
    function isEmail(email) {
        const re =
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    function validate() {
        for (const [key, value] of Object.entries(data)) {
            if (value.length === 0) {
                toast.error(key.toUpperCase() + " cannot be empty");
                return;
            }
            if (key === "Email" && !isEmail(value)) {
                toast.error(key.toUpperCase() + " wrong format");
                return;
            }
        }

        setIsValid(1);
        sendEmail();
    }

    function updateValue(key, value) {
        data[key] = value;
    }

    function decideDiv() {
        if (isValid === 1) {
            return (
                <>
                    <div class="loader" style={{ alignSelf: "center" }}></div>
                    <h2
                        style={{
                            color: K.Theme.primary4,
                            border: "none",
                            margin: "20px 10px",
                            textAlign: "center",
                            fontSize: "1.2rem",
                        }}
                    >
                        Sending...
                    </h2>
                </>
            );
        } else if (isValid === 0) {
            return (
                <Form
                    noValidate
                    autoComplete="off"
                    style={{ textAlign: "left" }}
                >
                    <TextField
                        id="standard-basic"
                        label="Name"
                        onChange={(event) =>
                            updateValue("Name", event.target.value)
                        }
                        className="grid-input"
                    />
                    <TextField
                        id="standard-basic"
                        label="Email"
                        onChange={(event) =>
                            updateValue("Email", event.target.value)
                        }
                        className="grid-input"
                    />
                    <TextField
                        onChange={(event) =>
                            updateValue("Your Message", event.target.value)
                        }
                        id="standard-multiline-static"
                        label="Your Message"
                        multiline
                        rows={5}
                        style={{ width: "100%", margin: "10px 0px" }}
                    />

                    <button type="button" onClick={() => validate()}>
                        Send
                    </button>
                </Form>
            );
        } else if (isValid === 2) {
            return (
                <>
                    <div>
                        <Tick size={150} />
                        <h2
                            style={{
                                color: K.Theme.primary4,
                                border: "none",
                                margin: "0px",
                                textAlign: "center",
                                fontSize: "1.2rem",
                            }}
                        >
                            Thanks for contacting us! We will to get in touch
                            with you shortly.
                        </h2>
                    </div>
                    <button
                        className="styled-button"
                        onClick={() => setIsValid(0)}
                    >
                        continue
                    </button>
                </>
            );
        }
    }

    return (
        <Box
            display="flex"
            flexDirection="column"
            width="100%"
            // height= "calc(100% - 60px)"
            justifyContent="left"
            position="relative"
            // marginBottom="110px"
            // bgcolor = {K.Theme.secondary} borderRadius={5} border={"3px solid " + K.Theme.primary} style={{opacity:'0.8'}}
        >
            {decideDiv()}
        </Box>
    );
}
