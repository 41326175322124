import React, { Component } from "react";
import styled from "styled-components";
import K from "../K";

const Div = styled.div`
    padding-top: 80px;
    padding-left: 100px;
    padding-right: 100px;
    text-align: left;

    h1 {
        span {
            color: ${K.Theme.secondary2};
        }
    }

    p {
        span {
            color: ${K.Theme.secondary2};
            font-weight: bolder;
        }
    }

    div {
        padding-left: 20px;
        div {
            p {
                padding-left: 20px;
            }
        }
    }
    .technologies {
        display: flex;
        flex-direction: row;
        img {
            height: 50px;
            width: 50px;
            margin: 5px;
        }
    }

    @media (max-width: 960px) {
        padding-left: 20px;
        padding-right: 20px;
    }
`;

class DataScience extends Component {
    state = {
        images: [],
    };

    importAll = (r) => {
        return r.keys().map(r);
    };

    componentDidMount() {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
        const images = this.importAll(
            require.context(
                "./../Assests/Services/Data Science",
                false,
                /\.(png|jpe?g|svg)$/
            )
        );
        console.log(images);
        this.setState({ images: images });
    }
    render() {
        return (
            <Div>
                <h1>
                    <span>D</span>ata Science
                </h1>
                {/* <h2>Technologies</h2> */}
                <div className="technologies">
                    {this.state.images.map((image) => {
                        return <img src={image.default}></img>;
                    })}
                </div>
                <p>
                    Reliant on artificial intelligence and machine learning
                    algorithms, we are committed to providing technical
                    solutions to our customers worldwide. Every business
                    generates a colossal amount of data and with our help, you
                    can turn this data into actionable insight which in return
                    is guaranteed to result in higher revenues.
                </p>
                <p>
                    So, what is data science? Data Science is the art of finding
                    patterns and drawing insight from data that would normally
                    be discarded. This process includes data extraction,
                    wrangling, and pre-processing. Sounds complex? Don’t worry,
                    with our team by your side, you'll never have to worry about
                    the complexity ever again! Together we'll draw important
                    insights from the data we collect and process it so you can
                    stay in the fast lane and achieve your goals.
                </p>
                <p>
                    Data Science has shown a huge impact on various aspects of
                    the digital environment. Financial savings have benefited
                    the most. Research by Mickinesky concluded that data science
                    efforts integrated into the US healthcare system could lead
                    to a{" "}
                    <a href="https://www.mckinsey.com/industries/healthcare-systems-and-services/our-insights/the-big-data-revolution-in-us-health-care">
                        $300 billion saving
                    </a>
                    . With more than 2.5 exabytes of data being generated every
                    day, it is crucial for your success that you become one of
                    the data-centric companies.
                </p>
                <p>
                    So how can data science help you? Well, with a large number
                    of ML (Machine Learning) algorithms at play our team
                    believes in using the ones that are best suited for your
                    business requirements. Our team uses the most trusted
                    algorithms to tackle data problems you might have.
                </p>
                <div>
                    <div>
                        <h3>1. Linear Regression</h3>
                        <p>
                            This algorithm will come into play when you need to
                            calculate the costs of your operations. Operational
                            costs include the cost of calls, sales, or pretty
                            much anything you count as part of your business. 2.
                            Logistic Regression
                        </p>
                    </div>
                    <div>
                        <h3>2. Logistic Regression</h3>
                        <p>
                            This is one of the most trusted classification
                            algorithms and operates by evaluating discrete
                            values i.e. ‘0’ or ‘1’. This algorithm comes in
                            handy particularly when we intend to predict the
                            outcome of a particular event so you can evaluate
                            whether or not a particular decision will badly
                            impact your operations. Decision-making has never
                            been easier.
                        </p>
                    </div>
                    <div>
                        <h3>3. Decision Tree</h3>
                        <p>
                            The decision tree is one of the most powerful and
                            widely used predictive tools. It offers great
                            flexibility so whichever field you're in, the
                            decision tree algorithm will always help make your
                            decision easier for you. They are particularly
                            helpful in evaluating past data to predict whether a
                            potential lead will be successful or not; mitigating
                            the risk from within any step you take on your
                            journey.
                        </p>
                    </div>
                    <div>
                        <h3>4. Support Vector Machine (SVM)</h3>
                        <p>
                            SVM has no competitor when it comes to
                            classification models. This is one of many
                            supervised machine learning algorithms primarily
                            used for the classification and categorization of
                            unseen data. SVM is used when tasks such as
                            handwriting recognition, face recognition, text
                            classification, and bioinformatics classification
                            are required.
                        </p>
                    </div>
                    <div>
                        <h3>5. Deep Learning </h3>
                        <p>
                            With deep learning algorithms, you can mimic human
                            decision-making using swift data computations,
                            pattern verification, and extensive data training.
                            Deep learning algorithms utilize artificial neural
                            networks that employ a layering mechanism; each
                            layer performs complex operations such as
                            abstraction, normalization, and weight evaluation.
                            You can find deep learning-based applications all
                            around you which include face identification in
                            pictures, object identification in real-time, or
                            even smart voice assistants we utilize in the form
                            of mobile assistants or smart homes.
                        </p>
                    </div>
                </div>
                <p>
                    DijkstraLabs has a team capable of developing solutions for you
                    based on other modern algorithms as well which include Naive
                    Bayes, kNN, kMeans, and Random Forest classifier.
                </p>
                <p>
                    Experts at DijkstraLabs are more than capable to craft a solution
                    for you and your data-centric problems. Services include:
                </p>
                <div>
                    <div>
                        <h3>1. Model Training and Re-Training</h3>
                        <p>
                            Our team is eager to help you collect the best data
                            out there so you can have the highest possible
                            accuracy at your disposal. With the right sample
                            output data and corresponding input data, we
                            guarantee you the highest optimization of your
                            algorithms.
                        </p>
                    </div>
                    <div>
                        <h3>
                            2. Computer Vision Development and Image Recognition
                        </h3>
                        <p>
                            With the help of our experts, you can not only
                            extract crucial data from within pictures and videos
                            but also process it and proceed to take action on
                            its behalf. Gone are the days when image recognition
                            and classification products were hard to get access
                            to.
                        </p>
                    </div>
                    <div>
                        <h3>3. Predictive Analytics</h3>
                        <p>
                            Our predictive analysis solutions will ensure that
                            you have an insight into the future and never make a
                            wrong decision ever again. With data-driven
                            decisions, you're one decision away from
                            revolutionizing and expanding your operations.
                        </p>
                    </div>
                    <div>
                        <h3>4. Cloud Integration</h3>
                        <p>
                            Our team not only provides cloud integration
                            services but also offers cloud migration services so
                            that you can go cloud-native and reap the rewards
                            you have been deprived of so far.
                        </p>
                    </div>
                    <div>
                        <h3>5. Speech Recognition</h3>
                        <p>
                            Integration of ‘Speech to Text’ is now easier than
                            it has ever been. Using advanced AI and ML
                            algorithms our team prides itself in delivering the
                            most accurate speech recognition products for you.
                        </p>
                    </div>
                    <div>
                        <h3>6. Virtual Assistant Development </h3>
                        <p>
                            Having highly optimized NLU and NLP algorithms, we
                            take pride in being the front runners in virtual
                            assistant development. Using our optimized and
                            efficient speech recognition and training models,
                            your virtual assistant is guaranteed to stand out
                            from the rest.
                        </p>
                    </div>
                    <div>
                        <h3>7. Graph Machine Learning (GML) Based Solutions</h3>
                        <p>
                            Utilizing concepts of graph theory and deep learning
                            GML based solutions offer solutions to problems that
                            no other ML algorithms can. GML-based
                            algorithm-based algorithms can learn from
                            traditional graph-based algorithms. Using these
                            sophisticated and technical networks you can do
                            anything from implementing a system that gives you
                            friends' suggestions on your social media platform,
                            monitoring and predicting traffic flows, predicting
                            the effect of a particular drug, or even detecting
                            cancer. The possibilities are endless; regardless of
                            the field.
                        </p>
                    </div>
                    <div>
                        <h3>8. NLU and NLP Integration Services</h3>
                        <p>
                            With our expertise in the world of NLP, you can now
                            process the linguistic data your business generates
                            and process it at unbelievable speeds followed by
                            the comprehension of said data using our vetted NLU
                            algorithms. With our expertise, you can now
                            integrate modern features such as text to speech
                            conversion, smart chatbots, named entity
                            recognition, and conduct social media analysis all
                            to give your business the edge it deserves to reach
                            the very top.
                        </p>
                    </div>
                </div>
            </Div>
        );
    }
}

export default DataScience;
