import React, { Component } from "react";

import styled from "styled-components";
import K from "../K";

const Div = styled.div`
    padding-top: 80px;
    text-align: left;
    padding-left: 100px;
    padding-right: 100px;
    h1 {
        color: ${K.Theme.secondary2};
    }
    div {
        padding: 0;
        padding: 1px 10px;
        background-color: #f6f0fb;
        border-color: ${K.Theme.secondary2};
        p {
            border-left-style: solid;
            padding: 10px;
        }
    }

    @media (max-width: 960px) {
        padding: 0px 10px;
        padding-top: 80px;
    }
`;

class Header extends Component {
    render() {
        return (
            <Div>
                <h1>Career</h1>
                <div>
                    <p>
                        COVID-19 and its attack has put the livelihood of
                        millions at risk; the attack has had a profound effect
                        on all communities, their well-being, and future
                        outlook. At DijkstraLabs we believe in standing with them, our
                        partners, clients, and future team members to help them
                        develop and venture forward even in these dark times.
                        We're actively accepting applications for open positions
                        and are offering remote work opportunities to facilitate
                        anyone who needs it the most. Let's grow and navigate
                        through these unprecedented times together!
                    </p>
                </div>
                <p>
                    Engineer, designer, software architect, or anyone who can
                    dream big and isn't afraid to fall short. We're always
                    looking to build our team, bring in individuals like you
                    that like a challenge, love to innovate, have a zest to
                    learn, like to indulge in workplace shenanigans, and work
                    with companies from all over the globe to take over the
                    world by storm.
                </p>
                <p>
                    Have what it takes? We can't wait to have you on board with
                    us. Drop your resume/CV below and we’ll get back to you as
                    soon as we can.
                </p>
            </Div>
        );
    }
}

export default Header;
