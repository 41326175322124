import React, { Component } from "react";
import styled from "styled-components";
import K from "../K";

const Div = styled.div`
    padding-top: 80px;
    padding-left: 100px;
    padding-right: 100px;
    text-align: left;

    h1 {
        span {
            color: ${K.Theme.secondary2};
        }
    }

    p {
        span {
            color: ${K.Theme.secondary2};
            font-weight: bolder;
        }
    }

    div {
        padding-left: 20px;
        div {
            p {
                padding-left: 20px;
            }
        }
    }

    .technologies {
        display: flex;
        flex-direction: row;
        img {
            height: 50px;
            width: 50px;
            margin: 5px;
        }
    }

    @media (max-width: 960px) {
        padding-left: 20px;
        padding-right: 20px;
    }
`;

class WebDevelopment extends Component {
    state = {
        images: [],
    };

    importAll = (r) => {
        return r.keys().map(r);
    };

    componentDidMount() {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
        const images = this.importAll(
            require.context(
                "./../Assests/Services/WEb Dev",
                false,
                /\.(png|jpe?g|svg)$/
            )
        );
        console.log(images);
        this.setState({ images: images });
    }
    render() {
        return (
            <Div>
                <h1>
                    <span>W</span>eb Development
                </h1>
                {/* <h2>Technologies</h2> */}
                <div className="technologies">
                    {this.state.images.map((image) => {
                        return <img src={image.default}></img>;
                    })}
                </div>
                <p>
                    <span>O</span>ver 200 million websites are active worldwide
                    as we speak, so why isn't your business website one of them?
                    With business and general trends inclining towards web-based
                    business models, your business must make the shift to
                    thrive. Web development is one of the hardest fields to
                    master but the team at DijkstraLabs just made it easier. Let's dig
                    in.
                </p>
                <p>
                    Trends in web development change quicker than they can be
                    implemented hence development teams need to stay updated
                    with the latest back-end, front-end frameworks, and
                    development techniques. The team at DijkstraLabs takes pride in
                    having expertise in the latest frameworks out in the market.
                </p>
                <p>
                    Front-end implementation refers to all aspects of a website
                    you (the client) can interact with. The front end of a
                    website has a huge impact on the usability of the website
                    and can affect you directly. DijkstraLabs ensures supremacy in the
                    implementation of various well-known front-end frameworks,
                    some of them include.
                </p>
                <div>
                    <div>
                        <h3>1. React</h3>
                        <p>
                            React is one of the most trusted front-end
                            frameworks given it utilizes Document Object Model
                            (DOM) that enables it to handle large amounts of
                            traffic and provide a resilient experience. React is
                            known for being used to deliver websites quickly
                            while ensuring the quality of the product.
                        </p>
                    </div>
                    <div>
                        <h3>2. Angular</h3>
                        <p>
                            Angular allows developers to utilize two-way data
                            binding that enables the team to see changes made in
                            the design in the view instantly. Angular is
                            particularly useful when talking about progressive
                            and multi-page web applications that utilize dynamic
                            data extensively.
                        </p>
                    </div>
                    <div>
                        <h3>3. Vue.JS</h3>
                        <p>
                            Vue is one of the simplest and most versatile
                            frameworks out there. Utilizing the best features
                            from other frameworks Vue.JS ensures easy
                            implementation and the ability to handle giant
                            dynamic web applications. Vue.Js also enables the
                            development team to design the application structure
                            with ease to ensure that the best product is
                            delivered to you.
                        </p>
                    </div>
                </div>
                <p>
                    <span>W</span>hile one can never underestimate the
                    importance of the front-end of a web application, the
                    back-end implementation is just as crucial. Back-end
                    implementation refers to the management and development of
                    the server-side of the application revolving around how the
                    core functionalities will operate. DijkstraLabs only uses the
                    latest and most trusted back-end development frameworks such
                    as.
                </p>
                <div>
                    <div>
                        <h3>1. Django</h3>
                        <p>
                            Django is one of the fastest-growing back-end
                            development frameworks with roots embedded into
                            python. Django is handy when you require quick
                            delivery, scalable, and highly secure web
                            applications. Django provides a high-security
                            environment preventing attacks such as SQL
                            injection, cross-site scripting all while ensuring
                            that operational expectations are always met.
                        </p>
                    </div>
                    <div>
                        <h3>2. Laravel</h3>
                        <p>
                            Laravel has taken the world by storm, simplifying
                            the implementation of web applications for small to
                            large scaled web applications. Laravel allows for
                            the integration of easy and secure authentication
                            along with simplistic API integration to ensure that
                            your users never have to wait for data to appear on
                            their screens.
                        </p>
                    </div>
                    <div>
                        <h3>3. Ruby on Rails</h3>
                        <p>
                            Ruby on Rails is a database efficiency-centric
                            framework that enables developers to quickly create
                            tables, migrate and manipulate them. ROR enables
                            cost-effective implementation and provides easy
                            testing functionalities so that only the highest
                            quality product is delivered into your hands.
                        </p>
                    </div>
                </div>
                <p>
                    <span>W</span>hy does any of this matter? Studies show that
                    94% of the first impressions are linked the website design
                    and implementation. The design may include the front-end
                    implementation or even the back-end design architecture to
                    ensure the smooth performance of the website. Regardless, it
                    is important that the user has a seamless experience which
                    is reliant on your implementation.
                </p>
                <p>
                    Over 70% of the users online abandon their shopping carts
                    due to poorly performing and designed websites reducing the
                    conversion rates. Proper web implementation is the only key
                    to ensure that customers convert and utilize the web based
                    product.
                </p>
                <p>The team here at DijkstraLabs can cater to all your needs,</p>
                <div>
                    <div>
                        <h3>1. Website Design/ReDesign</h3>
                        <p>
                            Web development teams at DijkstraLabs can build websites
                            for you that represents your business, and ensure a
                            flawless user experience so that you can maximize
                            your profits.
                        </p>
                    </div>
                    <div>
                        <h3>2. Web Portal Design</h3>
                        <p>
                            We’re experienced and skilled in designing web
                            portals that provide our customers with one-point
                            access to all the information they require.
                            Guaranteed to deliver you the most synchronized and
                            effective portal experience.
                        </p>
                    </div>
                    <div>
                        <h3>3. E-Commerce Development</h3>
                        <p>
                            From online transaction management to secure payment
                            gateways we at DijkstraLabs specialize in all aspects of
                            eCommerce development to cater to all your online
                            buying/selling business needs.
                        </p>
                    </div>
                    <div>
                        <h3>4. Progressive Web Applications</h3>
                        <p>
                            With experienced professionals on board, we can turn
                            your existing website into advanced progressive
                            websites or even make you a new one tailored to your
                            demand in no time!
                        </p>
                    </div>
                </div>
            </Div>
        );
    }
}

export default WebDevelopment;
