import React from "react";
import styled from "styled-components";
import K from "../K";
import { Link } from "react-router-dom";

const Ul = styled.ul`
    list-style: none;
    display: flex;
    flex-flow: row nowrap;
    padding: 0px;

    li {
        padding: 5px 10px;
        transition: all 0.3s linear;
        color: ${K.Theme.secondary};
        font-size: 1.2rem;

        :hover {
            color: ${K.Theme.secondary2};
            cursor: pointer;
        }
    }

    .dropbtn {
        background-color: transparent;
        color: ${K.Theme.secondary};
        padding: 8px 10px;
        font-size: 1.2rem;
        border: none;

        transition: all 0.3s linear;
    }

    /* The container <div> - needed to position the dropdown content */
    .dropdown {
        position: relative;
        display: inline-block;
    }

    /* Dropdown Content (Hidden by Default) */
    .dropdown-content {
        display: none;
        position: absolute;
        background-color: #f1f1f1;
        min-width: 400px;
        box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
        z-index: 1;
        left: -150px;
    }

    /* Links inside the dropdown */
    .dropdown-content a {
        color: ${K.Theme.secondary2};
        padding: 12px 16px;
        text-decoration: none;
        display: block;
        transition: all 0.3s linear;

        :hover {
            color: ${K.Theme.primary};
        }
    }

    /* Show the dropdown menu on hover */
    .dropdown:hover .dropdown-content {
        display: flex;
        flex-direction: row;
        justify-content: center;
        text-align: left;

        @media (max-width: 960px) {
            display: none;
        }
    }

    /* Change the background color of the dropdown button when the dropdown content is shown */
    .dropdown:hover .dropbtn {
        color: ${K.Theme.secondary2};
        cursor: pointer;
    }

    @media (max-width: 960px) {
        flex-flow: column nowrap;
        background-color: ${K.Theme.primary};
        position: fixed;
        transform: ${({ open }) =>
            open ? "translateX(0)" : "translateX(100%)"};
        top: 0;
        right: 0;
        height: 100vh;
        width: 250px;
        padding-top: 4rem;
        transition: 0.3s ease-in-out;
        text-align: left;

        li {
            color: ${K.Theme.secondary};
            padding: 8px 10px;

            :hover {
                color: ${K.Theme.primary4};
                cursor: pointer;
            }
        }
    }

    .navlink {
        text-decoration: none;
        color: inherit;
    }

    .expanded-div {
        /* border-left: 1px solid ${K.Theme.secondary}; */
        margin-left: 40px;

        li {
            padding: 5px 5px;
            /* margin-left: 40px; */
        }

        li a {
            text-decoration: none;
            color: ${K.Theme.secondary};

            :hover {
                color: ${K.Theme.primary4};
            }
        }
    }
`;

const RightNav = (props) => {
    function onClickItem() {
        props.setOpen(false);
    }

    function onClickService() {
        setExpanded(!expanded);
    }

    var [expanded, setExpanded] = React.useState(false);
    return (
        <Ul open={props.open}>
            <li onClick={() => onClickItem()}>
                <Link to={K.Routes.Home} className="navlink">
                    Home
                </Link>
            </li>
            <div class="dropdown">
                <button class="dropbtn" onClick={() => onClickService()}>
                    <Link className="navlink">Services</Link>
                </button>
                <div class="dropdown-content">
                    <div>
                        <Link to={K.Routes.UXUIDesign}>- UX/UI Design</Link>
                        <Link to={K.Routes.WebDevelopment}>
                            - Web Development
                        </Link>
                        <Link to={K.Routes.AppDevelopment}>
                            - App Development
                        </Link>
                        <Link to={K.Routes.CloudSolutions}>
                            - Cloud Solutions
                        </Link>
                        <Link to={K.Routes.NLPAndNLU}>- NLP and NLU</Link>
                    </div>

                    <div>
                        <Link to={K.Routes.DataScience}>- Data Science</Link>
                        <Link to={K.Routes.BigDataSolutions}>
                            - Big Data Solutions
                        </Link>
                        <Link to={K.Routes.DataEngineering}>
                            - Data Engineering
                        </Link>
                        <Link to={K.Routes.RealtimeAnalytics}>
                            - Realtime Analytics
                        </Link>
                        <Link to={K.Routes.BlockchainSolutions}>
                            - Blockchain Solutions
                        </Link>
                    </div>
                </div>
            </div>

            {expanded && props.open ? (
                <div className="expanded-div">
                    <li onClick={() => onClickItem()}>
                        <Link to={K.Routes.UXUIDesign}>- UX/UI Design</Link>
                    </li>
                    <li onClick={() => onClickItem()}>
                        <Link to={K.Routes.WebDevelopment}>
                            - Web Development
                        </Link>
                    </li>
                    <li onClick={() => onClickItem()}>
                        <Link to={K.Routes.AppDevelopment}>
                            - App Development
                        </Link>
                    </li>
                    <li onClick={() => onClickItem()}>
                        <Link to={K.Routes.CloudSolutions}>
                            - Cloud Solutions
                        </Link>
                    </li>
                    <li onClick={() => onClickItem()}>
                        <Link to={K.Routes.NLPAndNLU}>- NLP and NLU</Link>
                    </li>
                    <li onClick={() => onClickItem()}>
                        <Link to={K.Routes.DataScience}>- Data Science</Link>
                    </li>
                    <li onClick={() => onClickItem()}>
                        <Link to={K.Routes.BigDataSolutions}>
                            - Big Data Solutions
                        </Link>
                    </li>
                    <li onClick={() => onClickItem()}>
                        <Link to={K.Routes.DataEngineering}>
                            - Data Engineering
                        </Link>
                    </li>
                    <li onClick={() => onClickItem()}>
                        <Link to={K.Routes.RealtimeAnalytics}>
                            - Realtime Analytics
                        </Link>
                    </li>
                    <li onClick={() => onClickItem()}>
                        <Link to={K.Routes.BlockchainSolutions}>
                            - Blockchain Solutions
                        </Link>
                    </li>
                </div>
            ) : null}

            {/* <li><Link to={K.Routes.Home} className="navlink">Industeries</Link></li> */}
            {/* <li><Link to={K.Routes.Technologies} className="navlink">Technologies</Link></li> */}

            <li onClick={() => onClickItem()}>
                <Link to={K.Routes.Career} className="navlink">
                    Careers
                </Link>
            </li>
            <li onClick={() => onClickItem()}>
                <Link to={K.Routes.AboutUs} className="navlink">
                    About us
                </Link>
            </li>
            <li onClick={() => onClickItem()}>
                <Link to={K.Routes.Contact} className="navlink">
                    Contact
                </Link>
            </li>
        </Ul>
    );
};

export default RightNav;
