import React, { Component } from "react";
import styled from "styled-components";
import K from "../K";

const Div = styled.div`
    padding-top: 80px;
    padding-left: 100px;
    padding-right: 100px;
    text-align: left;

    h1 {
        span {
            color: ${K.Theme.secondary2};
        }
    }

    p {
        span {
            color: ${K.Theme.secondary2};
            font-weight: bolder;
        }
    }

    div {
        padding-left: 20px;
        div {
            p {
                padding-left: 20px;
            }
        }
    }

    .technologies {
        display: flex;
        flex-direction: row;
        img {
            height: 50px;
            width: 50px;
            margin: 5px;
        }
    }

    @media (max-width: 960px) {
        padding-left: 20px;
        padding-right: 20px;
    }
`;

class NLPAndNLU extends Component {
    state = {
        images: [],
    };

    importAll = (r) => {
        return r.keys().map(r);
    };

    componentDidMount() {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
        const images = this.importAll(
            require.context(
                "./../Assests/Services/NLP NLU",
                false,
                /\.(png|jpe?g|svg)$/
            )
        );
        console.log(images);
        this.setState({ images: images });
    }
    render() {
        return (
            <Div>
                <h1>
                    <span>N</span>LP and NLU
                </h1>
                {/* <h2>Technologies</h2> */}
                <div className="technologies">
                    {this.state.images.map((image) => {
                        return <img src={image.default}></img>;
                    })}
                </div>
                <p>
                    <span>With</span> business shifting online and the world
                    relying on them more than ever before, the demand for NLP
                    and NLU services is at an all-time high. So what is the hype
                    about? What is NLP? What is NLU? What's the difference? How
                    much potential growth is possible with NLP? Here at DijkstraLabs,
                    we have all the answers. Let's dig in.
                </p>
                <p>
                    NLP (Natural Language Processing) is the process of using
                    futuristic and modern AI models to process and break down
                    large amounts of linguistic data.
                </p>
                <p>
                    You may have seen NLP at work in NER (Named Entity
                    Recognition) based applications that quickly identify
                    integral information such as names and numbers to provide a
                    quick summary or may have used it in the form of chatbots,
                    tools that summarize text (BERT Based Applications), and
                    tools like{" "}
                    <a href="https://www.grammarly.com/">Grammarly</a> that can
                    identify the sentiments and tone delivery of textual data.
                    However, NLP does not work alone and has a smaller subset
                    known as NLU.
                </p>
                <p>
                    NLU and NLP work hand in hand in most cases. The concept of
                    NLU (Natural Language Understanding) revolves around
                    understanding the message the content aims to convey. NLU
                    algorithms evaluate the content by performing{" "}
                    <a href="https://en.wikipedia.org/wiki/Semantic_analysis_(linguistics)">
                        semantic analysis
                    </a>
                    , cross-checking sentence delivery, and verifying whether
                    two sentences said differently mean the same thing by
                    comparing the sentence similarity.
                </p>
                <p>
                    Here at DijkstraLabs, we have extensive amounts of experience with
                    Sequence sequence models (seq2seq) which are widely used in
                    translation, text summarization, and question-answering
                    systems.
                </p>
                <p>
                    Aiming for triple-digit growth in sales seemed impossible a
                    few years back but that is not the case anymore, thanks to
                    NLP. In 2020 alone, retailerAsos integrated a new chatbot
                    into their website and saw a{" "}
                    <a href="https://www.facebook.com/business/success/asos">
                        250% increase in return on spend and reached 3.5X
                    </a>{" "}
                    more people. Harnessing the power of NLP in their marketing
                    and UI model they achieved phenomenal success and so can
                    you!
                </p>
                <p>
                    DijkstraLabs has deep roots in the world of NLP. With expertise in
                    providing services such as
                </p>
                <div>
                    <div>
                        <h3>1. Text To Speech Conversion</h3>
                        <p>
                        DijkstraLabs offers the most advanced Text-Speech
                            conversion services so that customers can have a
                            flawless and bug-free experience. Text-Speech
                            conversion features are a necessity to have in all
                            modern applications whether desktop or mobile. With
                            increased reliance on this feature, it is integral
                            that you opt for a service providing the most
                            accurate and error-free Text-Speech integration
                            services.
                        </p>
                    </div>
                    <div>
                        <h3>2. Social Media Analysis </h3>
                        <p>
                            NLP provides deep analysis into what customers
                            expect and thinks of a particular product. These
                            insights help companies that utilize our services to
                            monitor and implement effective QA. Together we can
                            utilize the digital footprint and boost your
                            business.
                        </p>
                    </div>
                    <div>
                        <h3>3. Intelligent Chat Bot Setup</h3>
                        <p>
                            Chatbots are the first line of defense when it comes
                            to effective customer support. Chatbots ensure that
                            customers have a smooth experience and come back
                            again. This not only improves customer conversion
                            rate but also reduces hiring and operating costs.
                            Our Intelligent chatbots can perform a variety of
                            tasks, from helping the users navigate an
                            application or a web page to extracting useful
                            feedback from customers through surveys, they can do
                            it all.
                        </p>
                    </div>
                    <div>
                        <h3>4. Targeted Advertising</h3>
                        <p>
                            With our advanced and optimized keyword
                            matchingalgorithms, you can now display ads to
                            specific users. Users who search for a specific
                            keyword or product get to see the advertisement
                            only. This not only increases customer turnover but
                            also reduces your advertising costs.
                        </p>
                    </div>
                    <div>
                        <h3>5. Named Entity Recognition </h3>
                        <p>
                            Here at DijkstraLabs we take pride in offering top notch
                            NER services. Our experts offer optimized NER based
                            products. With expertise in fine tuning NER models
                            for better classification and categorization our NER
                            based products can boost your human resource,
                            customer support efforts and even make you stand out
                            in organic search rankings.
                        </p>
                    </div>
                </div>
            </Div>
        );
    }
}

export default NLPAndNLU;
