import React, { Component } from "react";
import styled from "styled-components";
import Header from "./Header";
import K from "./../K";
import ReadMore from "./ReadMore";
import Services from "./Services";
import Technologies from "./Technologies";
import Contact from "./Contact";

const Div = styled.div`
    background-color: ${K.Theme.primary};
`;

class index extends Component {
    componentDidMount() {
        console.log(localStorage["onClickServices"]);
        if (localStorage["onClickServices"] == "true") {
            console.log(localStorage["onClickServices"]);
            var elmnt = document.getElementById("services");
            if (elmnt != null) {
                elmnt.scrollIntoView();
            }
            localStorage["onClickServices"] = false;
        } else {
            document.body.scrollTop = 0; // For Safari
            document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
        }
    }
    render() {
        return (
            <Div>
                <Header />
                <ReadMore />
                <Services />
                <Contact />
            </Div>
        );
    }
}

export default index;
