import "./App.css";
import Navbar from "./Nav/Navbar";
import Home from "./Home";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect,
} from "react-router-dom";
import K from "./K";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Services from "./Services";
import Technologies from "./Technologies";
import UXUIDesign from "./Services/UXUIDesign";
import WebDevelopment from "./Services/WebDevelopment";
import AppDevelopment from "./Services/AppDevelopment";
import CloudSolutions from "./Services/CloudSolutions";
import NLPAndNLU from "./Services/NLPAndNLU";
import DataScience from "./Services/DataScience";
import BigDataSolutions from "./Services/BigDataSolutions";
import DataEngineering from "./Services/DataEngineering";
import RealtimeAnalytics from "./Services/RealtimeAnalytics";
import BlockchainSolutions from "./Services/BlockchainSolutions";
import Career from "./Career";
import AboutUS from "./AboutUs";
import Contact from "./Home/Contact";
import Footer from "./Footer";
function App() {
    return (
        <div className="App">
            <Router>
                <Navbar />
                <ToastContainer
                    limit={K.ToastNotification.limit}
                    autoClose={K.ToastNotification.duration}
                    role="dark"
                />
                <Switch>
                    <Route exact path={K.Routes.Home} component={Home} />
                    {/* <Services path={K.Routes.Services} component={Services}/> */}
                    {/* <Route path={K.Routes.Technologies} component={Technologies}/> */}
                    <Route path={K.Routes.Career} component={Career}></Route>

                    <Route path={K.Routes.Contact}>
                        <div style={{ paddingTop: "80px" }}>
                            <Contact />
                        </div>
                    </Route>

                    <Route path={K.Routes.UXUIDesign} component={UXUIDesign} />
                    <Route
                        path={K.Routes.WebDevelopment}
                        component={WebDevelopment}
                    />
                    <Route
                        path={K.Routes.AppDevelopment}
                        component={AppDevelopment}
                    />
                    <Route
                        path={K.Routes.CloudSolutions}
                        component={CloudSolutions}
                    />
                    <Route path={K.Routes.NLPAndNLU} component={NLPAndNLU} />
                    <Route
                        path={K.Routes.DataScience}
                        component={DataScience}
                    />
                    <Route
                        path={K.Routes.BigDataSolutions}
                        component={BigDataSolutions}
                    />
                    <Route
                        path={K.Routes.DataEngineering}
                        component={DataEngineering}
                    />
                    <Route
                        path={K.Routes.RealtimeAnalytics}
                        component={RealtimeAnalytics}
                    />
                    <Route
                        path={K.Routes.BlockchainSolutions}
                        component={BlockchainSolutions}
                    />

                    <Route path={K.Routes.AboutUS} component={AboutUS}></Route>
                </Switch>
                <Footer />
            </Router>
        </div>
    );
}

export default App;
