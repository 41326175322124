import React, { Component } from "react";
import styled from "styled-components";
import K from "../K";

const Div = styled.div`
    padding-top: 80px;
    padding-left: 100px;
    padding-right: 100px;
    text-align: left;

    h1 {
        span {
            color: ${K.Theme.secondary2};
        }
    }

    p {
        span {
            color: ${K.Theme.secondary2};
            font-weight: bolder;
        }
    }

    div {
        padding-left: 20px;
        div {
            p {
                padding-left: 20px;
            }
        }
        p {
            span {
                color: ${K.Theme.secondary};
                font-weight: bold;
            }
        }
    }
    .technologies {
        display: flex;
        flex-direction: row;
        img {
            height: 50px;
            width: 50px;
            margin: 5px;
        }
    }

    @media (max-width: 960px) {
        padding-left: 20px;
        padding-right: 20px;
    }
`;

class CloudSolutions extends Component {
    state = {
        images: [],
    };

    importAll = (r) => {
        return r.keys().map(r);
    };

    componentDidMount() {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
        const images = this.importAll(
            require.context(
                "./../Assests/Services/Cloud Solutions",
                false,
                /\.(png|jpe?g|svg)$/
            )
        );
        console.log(images);
        this.setState({ images: images });
    }
    render() {
        return (
            <Div>
                <h1>
                    <span>C</span>loud Solutions
                </h1>
                {/* <h2>Technologies</h2> */}
                <div className="technologies">
                    {this.state.images.map((image) => {
                        return <img src={image.default}></img>;
                    })}
                </div>
                <p>
                    <span>F</span>rom operations management to secure file
                    holding, the world is going through a digital
                    transformation. We don't see a reason why you along with
                    your business shouldn't.
                </p>
                <p>
                    Spearheading this revolution is the cloud technology that
                    has developed over the past 2 decades and has matured fully.
                    With the latest cloud technology, your ideas, and our
                    services paired together, we will be a force to reckon with.
                </p>
                <p>
                    Cloud technology harnesses the power of virtualization that
                    enables the delivery of on-demand storage, processing
                    resources, and other cloud services so you don't have to
                    worry about down-times, maintenance, or even having to
                    purchase large machines and pay extra money for it.
                </p>
                <p>
                    The benefits of cloud tech are endless but we've narrowed
                    them down to a few points to convince why you should go
                    cloud-native.
                </p>
                <p>
                    Cost-Effectiveness is the first and the most significant
                    benefit cloud computing brings with itself. With cloud
                    services, YOU PAY FOR WHAT YOU USE. Not a penny extra. Let
                    us set up your system and help you save capital so you can
                    invest where it really matters. Just how big of a difference
                    does migrating to the cloud make you might wonder? Recent
                    research by NSK.inc revealed that about{" "}
                    <a href="https://info.focustsi.com/topic/Cloud-computing/IT-Services-Boston/7-Statistics-You-Didn-t-Know-About-Cloud-Computing">
                        82% of companies reported significant savings
                    </a>{" "}
                    after shifting to the cloud.
                </p>
                <p>
                    To have a competitive edge in the market your business must
                    be scalable. Your business might need numerous servers one
                    day and might not on another (IaaS- Infrastructure as a
                    Service). Cloud services ensure that you only pay for what
                    you use when you need it. SaaS (Software as a Service) based
                    cloud services enable your business to utilize expensive
                    enterprise software for short periods and only pay for the
                    time you utilize them for. Bang for the buck!
                </p>
                <p>
                    Cloud-native businesses thrive because they never have to
                    worry about data backups and security. Thanks to the cloud,
                    your data and information are more accessible and secure
                    than ever. With data fragmentation at play along with the
                    existence of multiple data copies, you'll never lose your
                    valuable data, no matter what the circumstances are.
                </p>
                <p>
                    With the ever-growing cloud market, you have a variety of
                    platforms and features at your disposal.
                </p>
                <div>
                    <div>
                        <h3>1. Kubernetes Deployment</h3>
                        <p>
                            Kubernetes systems help automate the intricate
                            process of deployment, scaling, and management of
                            containerized apps. With the help of our team and
                            the platforms such as ‘AKS’ (Amazon Elastic
                            Kubernetes Service) and ‘GCP’ (Google Cloud
                            Platform), you can now run Kubernetes without having
                            to worry about installation and operating
                            complexities of the Kubernetes nodes.
                        </p>
                    </div>
                    <div>
                        <h3>2. Infrastructure as Code</h3>
                        <p>
                            With agile development being the favorite choice of
                            cloud development, development teams need to pick up
                            the pace and keep up; also having to deal with
                            repetitive and rapid deployments. Infrastructure as
                            code allows you to merge your operations, and
                            development by defining the infrastructure that
                            needs to be deployed in the code itself. With this
                            code secure in your repository you can push it,
                            alter it and deploy it in similar environments
                            whenever you deem necessary minus all the hassle of
                            covenetial deployment and operations.
                        </p>
                    </div>
                    <div>
                        <h3>3. Provision Free Code Execution</h3>
                        <p>
                            With the help of cloud services, you can now execute
                            code without managing and overseeing servers
                            yourself. Such services run the code only when you
                            need and scale it when necessary; you can run code
                            for any back-end service or application. With
                            platforms such as ‘AWS Lambda’, ‘Azure Automation’
                            and many more paired with our energetic team code
                            deployment and scaling has never been easier.
                        </p>
                    </div>
                    <div>
                        <h3>4. Web Hosting and Pay as You Scale </h3>
                        <p>
                            Cloud hosting can save you the trouble of server
                            management, storage, and network asset
                            orchestration; instead of focusing on these, you can
                            choose to focus on your websites. Let our team and
                            platforms such as Azure App Services, AWS Hosting,
                            and many more provide robust and secure access to
                            your site. This enables you to have CDN or a data
                            center host your website from the location of your
                            choosing from all over the world without having to
                            put in tremendous amounts of effort.
                        </p>
                    </div>
                    <div>
                        <h3>5. Cluster Platforms For Big Data</h3>
                        <p>
                            Modern cloud services and platforms let us simply
                            run load-intensive big data frameworks such as
                            Apache Hadoop, Spark to process and analyze data.
                            With many popular market options such as Azure Data
                            Bricks, Amazon EMR, and many more available for
                            utilization you can now analyze huge loads of data
                            and can also move it about in and out of cloud
                            storage dumps at blazing speeds.
                        </p>
                    </div>
                    <div>
                        <h3>6. Pay As You Go</h3>
                        <p>
                            All cloud platforms allow you to select between
                            plans of flexible pricing so you only pay for what
                            you use and the solutions you require. This saves
                            you the cost of infrastructure and software
                            licensing. Services such as AWS EC2 allow you to
                            acquire more server-based resources during peak
                            hours and scale back when there’s no traffic on your
                            domain. Other platforms also offer similar features
                            such as Azure RI’s which allow you to receive a VM
                            and can{" "}
                            <a href="https://azure.microsoft.com/en-us/pricing/reserved-vm-instances/">
                                save up to as much as 72%.
                            </a>
                            .
                        </p>
                    </div>
                </div>
                <p>
                    Reports by Report Linker suggest that the{" "}
                    <a href="https://hostingtribunal.com/blog/cloud-computing-statistics/#gref">
                        cloud compute market is expected to hit the $600
                    </a>{" "}
                    Billion mark in 2021 and our team at DijkstraLabs is eager to 
                    make sure that you are a part of this projection. DijkstraLabs
                    has operational and configuration expertise in the latest cloud
                    platforms but you don't have to worry about which. We
                    provide cloud-agnostic solutions that ensure that a single
                    cloud deployment can be implemented across multiple cloud
                    platforms. This minimizes cross-platform dependencies and
                    saves you the management, orchestration of managing a
                    large-scale cloud-based infrastructure.
                </p>
                <p>
                    DijkstraLabs specializes in setting up cloud services for
                    you. DijkstraLabs believes in forming great partnerships so
                    we won't stop at just setting it up for you, but also maintain
                    and help you grow on the cloud. DijkstraLabs provides many
                    cloud-based services, some of which include,
                </p>
                <div>
                    <p>
                        1. <span>AWS</span> Integration and Security Service.
                        <br /> 2. <span>Azure</span> Migration and Set-Up
                        Services.
                        <br /> 3. <span>Google Cloud</span> Platform Support and
                        Integration.
                        <br />
                        4. Cloud-Based <span>ERP</span> System Development.
                    </p>
                </div>
                <p>
                    Our cloud-agnostic services help you opt for the right cloud
                    in the way it works best for your business and strategic
                    goals without having to worry about infrastructure costs,
                    effort, and effort of choosing the right cloud platform
                    yourself.
                </p>
            </Div>
        );
    }
}

export default CloudSolutions;
