import React, { Component } from "react";
import styled from "styled-components";
import K from "../K";

const Div = styled.div`
    padding-top: 80px;
    padding-left: 100px;
    padding-right: 100px;
    text-align: left;

    h1 {
        span {
            color: ${K.Theme.secondary2};
        }
    }

    p {
        span {
            color: ${K.Theme.secondary2};
            font-weight: bolder;
        }
    }

    div {
        padding-left: 20px;
        div {
            p {
                padding-left: 20px;
            }
        }
    }
    .technologies {
        display: flex;
        flex-direction: row;
        img {
            height: 50px;
            width: 50px;
            margin: 5px;
        }
    }

    @media (max-width: 960px) {
        padding-left: 20px;
        padding-right: 20px;
    }
`;

class DataEngineering extends Component {
    state = {
        images: [],
    };

    importAll = (r) => {
        return r.keys().map(r);
    };

    componentDidMount() {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
        const images = this.importAll(
            require.context(
                "./../Assests/Services/Data Engineering",
                false,
                /\.(png|jpe?g|svg)$/
            )
        );
        console.log(images);
        this.setState({ images: images });
    }
    render() {
        return (
            <Div>
                <h1>
                    <span>D</span>ata Engineering
                </h1>
                {/* <h2>Technologies</h2> */}
                <div className="technologies">
                    {this.state.images.map((image) => {
                        return <img src={image.default}></img>;
                    })}
                </div>
                <p>
                    You've collected petabytes of data but what use would data
                    be if it wasn't in a usable state? This calls for the help
                    of smart and modern data engineering solutions. With the
                    help of expert data engineers, you can build pipelines that
                    ensure that data not only reaches where it's meant to go but
                    also reaches there in the format intended. Data pipelines
                    collect data from multiple streams and represent it as a
                    single viable data source.
                </p>
                <p>
                    Data engineering requires a highly sharpened skill set.
                    Without the right set of data architecture integrated into
                    your business, all data science, analytics, and monitoring
                    efforts may prove to be futile.
                </p>
                <p>
                    Gartner’s determination back in 2017 suggested that{" "}
                    <a href="https://quanthub.com/what-is-data-engineering/">
                        85% of all big data projects failed due to the lack of
                        proper data architecture
                    </a>
                    ; which is why the team at DijkstraLabs is ready to work with
                    you to deliver the most cutting-edge data engineering solutions
                    out in the market. With data engineering done right, your
                    data science efforts will never go to waste.
                </p>
                <p>
                    How does it work and how do we do it? The process begins
                    when our data engineers collect raw data from your systems
                    by using ETL and ELT operation pipelines (Extract Transform
                    Load and Extract Load Transform). This data is then
                    pre-processed and stored in data lakes and data warehouses
                    for utilization. How does this help? This effort provides
                    data scientists with actionable data, data they can rely on
                    to generating insights for your business.
                </p>
                <p>Data Engineering can help you too</p>
                <div>
                    <div>
                        <p>
                            ● By converting heterogeneous data into a standard
                            format so that your data utilization and training
                            process becomes streamlined.
                        </p>
                    </div>
                    <div>
                        <p>
                            ● By automating pipelines for real-time data which
                            is to be used by analytics solutions.
                        </p>
                    </div>
                    <div>
                        <p>
                            ● By encrypting data according to modern security
                            and government compliance standards.
                        </p>
                    </div>
                    <div>
                        <p>
                            ● By allowing you to customize data structures
                            through data wrangling so your data scientists
                            receive data the way they want it to be.
                        </p>
                    </div>
                    <div>
                        <p>
                            ● By enabling you to conduct real-time data
                            analytics using modern tools such as Kafka, Spark,
                            and data-bricks.
                        </p>
                    </div>
                </div>
                <p>
                    DijkstraLabs has substantial experience in the field of data
                    engineering and has provided the best data engineering
                    solutions in the market for a long time. We believe together
                    we can help you streamline and mitigate all your
                    data-specific problems so you never have to worry about them
                    ever again. Our offerings include,
                </p>
                <div>
                    <div>
                        <h3>1. Data Infrastructure Solutions</h3>
                        <p>
                            With data experts having years of experience, we can
                            design and implement your data lakes, deploy them
                            with different models (hybrid or cloud) and provide
                            you solutions for real-time and batch processing of
                            data for AI and ML utilization.
                        </p>
                    </div>
                    <div>
                        <h3>2. Data Engineering Solutions</h3>
                        <p>
                            Development of the most optimized ETL /ELT
                            pipelines, product development along with APIs, DQM
                            (Data Quality Management) system development for
                            audits, we cater to all your data engineering needs.
                        </p>
                    </div>
                    <div>
                        <h3>3. Data Governance Services </h3>
                        <p>
                            Our team of compliance analysts is eager to help you
                            and ensure that your data meets the necessary data
                            laws and GDPR compliance so you can proceed to do
                            business hassle-free. We also deliver data security
                            audit services along with the establishment of
                            access policies for your organization.
                        </p>
                    </div>
                    <div>
                        <h3>4. Data Operations</h3>
                        <p>
                            DijkstraLabs along with its team of tech industry
                            veterans offers management solutions in Data Lake
                            and RDBMS, DBA Services, and data quality control
                            so you can always have the best data at your disposal.
                        </p>
                    </div>
                </div>
            </Div>
        );
    }
}

export default DataEngineering;
