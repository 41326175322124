import React, { Component } from "react";
import styled from "styled-components";
import contactImagePng from "./../Assests/contactImage.png";
import K from "./../K";
import ContactForm from "./ContactForm";

const Div = styled.div`
    display: flex;
    text-align: left;
    justify-content: space-between;
    padding-top: 80px;
    width: 100%;
    .headerImg {
        height: 30rem;
    }
    .titleDiv {
        margin-left: 100px;
        width: 40%;
        h2 {
            color: ${K.Theme.secondary2};
            text-decoration: underline;
        }
    }
    @media (max-width: 960px) {
        display: inline;
        .headerImg {
            height: 15rem;
            width: 100%;
        }
        img {
            display: none;
        }
        .titleDiv {
            margin-left: 0px;
            padding: 10px;
            width: calc(100% - 20px);
            h2 {
                color: ${K.Theme.secondary2};
                text-decoration: underline;
            }
        }
    }
    .loader {
        font-size: 10px;
        text-indent: -9999em;
        width: 5rem;
        height: 5rem;
        border-radius: 50%;
        background: ${K.Theme.secondary2};
        background: -moz-linear-gradient(
            left,
            ${K.Theme.secondary2} 10%,
            rgba(255, 255, 255, 0) 42%
        );
        background: -webkit-linear-gradient(
            left,
            ${K.Theme.secondary2} 10%,
            rgba(255, 255, 255, 0) 42%
        );
        background: -o-linear-gradient(
            left,
            ${K.Theme.secondary2} 10%,
            rgba(255, 255, 255, 0) 42%
        );
        background: -ms-linear-gradient(
            left,
            ${K.Theme.secondary2} 10%,
            rgba(255, 255, 255, 0) 42%
        );
        background: linear-gradient(
            to right,
            ${K.Theme.secondary2} 10%,
            rgba(255, 255, 255, 0) 42%
        );
        position: relative;
        -webkit-animation: load3 0.5s infinite linear;
        animation: load3 0.5s infinite linear;
        -webkit-transform: translateZ(0);
        -ms-transform: translateZ(0);
        transform: translateZ(0);
    }

    .loader:before {
        width: 50%;
        height: 50%;
        background: ${K.Theme.secondary2};
        border-radius: 100% 0 0 0;
        position: absolute;
        top: 0;
        left: 0;
        content: "";
    }

    .loader:after {
        background: ${K.Theme.primary2};
        width: 75%;
        height: 75%;
        border-radius: 50%;
        content: "";
        margin: auto;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
    }

    @-webkit-keyframes load3 {
        0% {
            -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
        }
        100% {
            -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
        }
    }

    @keyframes load3 {
        0% {
            -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
        }
        100% {
            -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
        }
    }
    .styled-button {
        background-color: ${K.Theme.secondary2};
        color: ${K.Theme.primary2};
        /* display: flex; */
        /* justify-content: center; */
        width: calc(100% - 10px);
        border: none;
        padding: 10px;
        border-radius: 5px;
        font-size: 1rem;
        transition: all 0.3s linear;
        margin: 5px 5px;

        :hover {
            background-color: ${K.Theme.primary2};
            color: ${K.Theme.secondary2};
            cursor: pointer;
        }
    }
`;
class Contact extends Component {
    render() {
        return (
            <Div>
                <div className="titleDiv">
                    <h2>Get In Touch</h2>
                    <h3>Leave us a message</h3>
                    <div>
                        <ContactForm />
                    </div>
                </div>
                <img src={contactImagePng} className="headerImg" />
            </Div>
        );
    }
}

export default Contact;
