class K {
    static Theme = class {
        static primary = "#E5E5E5";
        static secondary = "#000000";

        static primary2 = "#FFFFFF";
        static secondary2 = "#7339A0";

        static secondary3 = "#5A545F";
    };

    static Routes = class {
        static Home = "/";
        static Services = "/Services";
        static Technologies = "/Technologies";
        static Career = "/Career";
        static AboutUs = "/AboutUs";
        static Contact = "/Contact";

        static UXUIDesign = "/UXUIDesign";
        static WebDevelopment = "/WebDevelopment";
        static AppDevelopment = "/AppDevelopment";
        static CloudSolutions = "/CloudSolutions";
        static NLPAndNLU = "/NLPAndNLU";
        static DataScience = "/DataScience";
        static BigDataSolutions = "/BigDataSolutions";
        static DataEngineering = "/DataEngineering";
        static RealtimeAnalytics = "/RealtimeAnalytics";
        static BlockchainSolutions = "/BlockchainSolutions";
    };

    static ToastNotification = class {
        static limit = 3;
        static duration = 3 * 1000;
    };
}

export default K;
