import React, { Component } from "react";
import Contact from "../Home/Contact";
import Header from "./Header";

class AboutUS extends Component {
    componentDidMount() {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    }
    render() {
        return (
            <>
                <Header />
                <Contact />
            </>
        );
    }
}

export default AboutUS;
