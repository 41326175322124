import React from "react";
import styled from "styled-components";
import K from "../K";
import { Link } from "react-router-dom";

const Ul = styled.ul`
    list-style: none;
    display: flex;
    flex-flow: row-reverse nowrap;
    background-color: ${K.Theme.secondary2};
    color: ${K.Theme.primary2};
    align-items: center;
    padding: 20px 50px;
    width: (100% - 100px);
    /* height: px; */
    margin: 0px;

    li {
        padding: 0px 10px;

        :hover {
            color: ${K.Theme.primary};
        }
    }

    button {
        background-color: ${K.Theme.primary};
        color: ${K.Theme.secondary};
        padding: 15px 10px;
        border: 0px;
        border-radius: 5px;
        transition: all 0.3s linear;

        :hover {
            background-color: ${K.Theme.secondary};
            color: ${K.Theme.primary};
            cursor: pointer;
        }
    }
    h1 {
        position: absolute;
        left: 100px;
    }

    @media (max-width: 960px) {
        flex-flow: column-reverse nowrap;
        height: auto;
        /* width: 100%; */

        li {
            color: ${K.Theme.primary2};
            padding: 10px 10px;
        }
        h1 {
            display: none;
        }
    }

    .navlink {
        text-decoration: none;
        color: inherit;
    }
`;

const Footer = ({ open }) => {
    function onClickServices() {
        var elmnt = document.getElementById("services");
        if (elmnt != null) {
            elmnt.scrollIntoView();
        } else {
            localStorage["onClickServices"] = true;
        }
    }
    return (
        <Ul open={open}>
            <li>
                <Link to={K.Routes.Contact} className="navlink">
                    Contact
                </Link>
            </li>
            <li>
                <Link to={K.Routes.AboutUs} className="navlink">
                    About us
                </Link>
            </li>
            <li>
                <Link to={K.Routes.Career} className="navlink">
                    Careers
                </Link>
            </li>
            <li onClick={onClickServices}>
                <Link to={K.Routes.Home} className="navlink">
                    Services
                </Link>
            </li>
            <li>
                <Link to={K.Routes.Home} className="navlink">
                    Home
                </Link>
            </li>
            <h1>DijkstraLabs</h1>

            {/* <button><Link to={K.Routes.Contact} className="navlink">KONTAKT</Link></button> */}

            {/* <button onClick={()=>window.scrollTo(0, 0)} style={{ transform: "rotate(90deg)", right:"20px", position: "absolute"}}>«</button> */}
        </Ul>
    );
};

export default Footer;
