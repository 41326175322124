import React, { Component } from "react";
import styled from "styled-components";
import Header from "./Header";
import ReadMore from "./ReadMore";
import K from "../K";

const Div = styled.div`
    background-color: ${K.Theme.primary};
`;

class index extends Component {
    render() {
        return (
            <Div>
                <Header />
                <ReadMore />
            </Div>
        );
    }
}

export default index;
