import React, { Component } from "react";
import styled from "styled-components";
import K from "../K";
import technologiesHeaderPng from "./../Assests/technologiesHeader.png";

const Div = styled.div`
    display: flex;
    text-align: left;
    justify-content: space-between;
    padding-top: 80px;
    width: 100%;
    margin-bottom: 50px;
    div {
        margin-top: auto;
        margin-bottom: auto;
        width: 500px;
        margin-left: 100px;
        img {
            height: 2rem;
        }
        h1 {
            color: ${K.Theme.secondary2};
        }
    }
    .headerImg {
        height: 30rem;
    }
    @media (max-width: 960px) {
        display: inline;
        .headerImg {
            height: 15rem;
            width: 100%;
        }
        div {
            text-align: center;
            margin-left: 0;
            width: auto;
            padding: 10px;
            padding-top: 80px;
        }
    }
`;
class Header extends Component {
    render() {
        return (
            <Div>
                <div>
                    <h1>Industeries</h1>
                    <h3>We are changing the world at a rapid pace.</h3>
                    <p>
                        With over 270,000 team members in almost 50 countries
                        countries and partnerships with a broad spectrum of
                        technology vendors, Capgemini has both the resources and
                        expertise to react quickly to client needs — a key asset
                        in a digital era when deployment needs to be measured in
                        weeks rather than months.
                    </p>
                </div>
                <img src={technologiesHeaderPng} className="headerImg" />
            </Div>
        );
    }
}

export default Header;
