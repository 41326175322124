import React, { useState } from 'react';
import styled from 'styled-components';
import RightNav from './RightNav';
import K from './../K'

const StyledBurger = styled.div`

    width: 2rem;
    height: 2rem;
    position: fixed;
    top: 10px;
    right: 10px;
    z-index: 20;
    display: none;

    @media (max-width: 960px) {

        display: flex;
        justify-content: space-around;
        flex-flow: column nowrap;
        
        margin: 0;
        position: absolute;
        top: 50%;
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);

    }
    
    div {

        width: 2rem;
        height: 0.25rem;
        background-color: ${({ open }) => open ? K.Theme.secondary2 : K.Theme.secondary2};
        border-radius: 10px;
        transform-origin: 1px;
        transition: all 0.3s linear;

        &:nth-child(1) {

            transform: ${({ open }) => open ? 'rotate(45deg)' : 'rotate(0deg)'};

        }

        &:nth-child(2) {

            transform: ${({ open }) => open ? 'translateX(100%)' : 'translateX(0)'};
            opacity: ${({ open }) => open ? 0 : 1}; 

        }

        &:nth-child(3) {

            transform: ${({ open }) => open ? 'rotate(-45deg)' : 'rotate(0deg)'};

        }

    }

`

const Burger = () => {

    const [open, setOpen] = useState(false)

    function onBurgerClick() {

        setOpen(!open)
    }
    
    return (
        <>

            <StyledBurger open = {open} onClick={() => onBurgerClick()}>

                <div />
                <div />
                <div />

            </StyledBurger>
            <RightNav open = {open} setOpen = {setOpen} />

        </>

    );

}

export default Burger;