import React, { Component } from "react";
import styled from "styled-components";
import K from "../K";

const Div = styled.div`
    padding-top: 80px;
    padding-left: 100px;
    padding-right: 100px;
    text-align: left;

    h1 {
        span {
            color: ${K.Theme.secondary2};
        }
    }

    div {
        padding-left: 20px;
        div {
            p {
                padding-left: 20px;
            }
        }
    }

    .technologies {
        display: flex;
        flex-direction: row;
        img {
            height: 50px;
            width: 50px;
            margin: 5px;
        }
    }

    @media (max-width: 960px) {
        padding-left: 20px;
        padding-right: 20px;
    }
`;

class AppDevelopment extends Component {
    state = {
        images: [],
    };

    importAll = (r) => {
        return r.keys().map(r);
    };

    componentDidMount() {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
        const images = this.importAll(
            require.context(
                "./../Assests/Services/App Dev",
                false,
                /\.(png|jpe?g|svg)$/
            )
        );
        console.log(images);
        this.setState({ images: images });
    }

    render() {
        return (
            <Div>
                <h1>
                    <span>A</span>pplication Development
                </h1>
                {/* <h2>Technologies</h2> */}
                <div className="technologies">
                    {this.state.images.map((image) => {
                        return <img src={image.default}></img>;
                    })}
                </div>
                <p>
                    By the end of 2021{" "}
                    <a href="https://www.statista.com/statistics/218984/number-of-global-mobile-users-since-2010/">
                        mobile users are expected to reach as high as 7 billion
                    </a>{" "}
                    while the annual app download rate is increasing at a steady
                    rate of 7% worldwide. With the digital environment growing
                    at a rapid pace, this is your chance to jump on the
                    bandwagon and revolutionize how you do business.
                </p>
                <p>
                    Application development can do wonders for your business.
                    With features such as push notifications, app-specific
                    discounts, and promotional notifications you can motivate
                    your customer base to go the extra mile and perform the
                    actions you want them to. Applications allow you to
                    integrate loyalty programs so that your customers never
                    leave your side, and you always have a competitive edge in
                    the market.
                </p>
                <p>
                    Application development also gives you the chance to correct
                    your course and make improvements to your business model or
                    the strategy you have adopted; applications can record
                    non-invasive data and analytical figures that can give you
                    an insight into what the user likes and dislikes about your
                    product.
                </p>
                <p>
                    You can optimize your marketing and business operation
                    efforts all through developing an application. With full
                    ownership of your application, you can send in customized
                    messages in the form of notifications and market your
                    product better than ever before.
                </p>
                <p>
                    The team at DijkstraLabs is competent and has mastered the art of
                    application development. With teams skilled and up to date
                    with the latest development trends and development
                    frameworks you can have your app ready in no time. Our team
                    specializes in the latest, most well-known, and hard to
                    master frameworks such as.
                </p>
                <div>
                    <div>
                        <h3>1. React Native</h3>
                        <p>
                            With Facebook and its industrial might behind the
                            wheel, react-native delivers one of the best
                            cross-platform development platforms ever. React is
                            the choice to go with for many of the customers out
                            there because of its third-party plug-in support,
                            code reusability, superb performance and live reload
                            feature. React Native allows developers to code in
                            pure JavaScript which is compatible with both iOS
                            and Android so you can take over both worlds
                            simultaneously.
                        </p>
                    </div>
                    <div>
                        <h3>2. Flutter</h3>
                        <p>
                            Flutter comes with numerous benefits, the best one
                            being that the developers do not need to have two
                            sets of codes and can launch an application for both
                            iOS and Android platforms. Flutter is the only
                            choice that comes to mind when we think of speedy
                            development and deployment. With the best IDE
                            support in the business, Flutter has taken the world
                            by storm.
                        </p>
                    </div>
                    <div>
                        <h3>3. Ionic</h3>
                        <p>
                            Hybrid apps, progressive apps, and cross-platform
                            apps, Iconic has every type of app development
                            covered. Iconic delivers a beautiful and easily
                            customizable UI that allows you the freedom to get
                            your application designed the way you want to.
                            Iconic takes the lead with its ready-made templates
                            and optimized APIs so you can minimize your load
                            times and get to where you want to be in a jiffy.
                        </p>
                    </div>
                    <div>
                        <h3>4. Xamarin</h3>
                        <p>
                            This framework lets our developers harness the power
                            of .NET and C# to deliver the most customizable and
                            powerful apps out there. Xamarin offers you a
                            cost-effective approach to develop apps for not one,
                            not two but THREE platforms i.e, iOS, Android, and
                            Windows. To let the developers have full control
                            over the design, Xamarin allows them to use CSS
                            libraries all while providing the best performance
                            for online and offline applications.
                        </p>
                    </div>
                </div>
                <p>
                    With apps accounting for around{" "}
                    <a href="https://zudu.co.uk/blog/mobile-app-stats/">
                        mobile users are expected to reach as high as 7 billion
                    </a>{" "}
                    you must have the best- designed applications for your
                    business. DijkstraLabs provides an array of services that include,
                </p>
                <div>
                    <div>
                        <h3>1. Android Application Development</h3>
                        <p>
                            DijkstraLabs is fully equipped to deliver the most modern
                            and finely designed android applications built using
                            top tools such as ‘Eclipse’ and ‘Android Studio’.
                        </p>
                    </div>
                    <div>
                        <h3>2. iOS Application Development</h3>
                        <p>
                            Using tools such as XCODE, MockingBird, Marvel, and
                            many more we promise you to deliver the smoothest
                            operating and performing iOS application there can
                            be; tailored just the way you want it.
                        </p>
                    </div>
                    <div>
                        <h3>3. Windows Application Development</h3>
                        <p>
                            We have deep roots in .NET and Visual Studio
                            development, DijkstraLabs believes no challenge can stop
                            them from handing over the best and most efficient
                            windows application in your hands.
                        </p>
                    </div>
                    <div>
                        <h3>4. API Integration Services</h3>
                        <p>
                            With experience and years of hands-on expertise in
                            the world of development, we specialize in
                            integrating third-party APIs with existing
                            businesses so you can use data to reach your next
                            milestone quicker than you ever thought you could.
                        </p>
                    </div>
                    <div>
                        <h3>5. Web-Based Applications</h3>
                        <p>
                            Compared to platform-specific applications,
                            web-based applications offer a completely different
                            set of benefits and open the gate to new
                            possibilities. This enables you to offer your
                            services, your product across multiple environments
                            using the internet alone. Web- based development
                            also offers enhanced security and above all supreme
                            customizability along with scalability.{" "}
                            <a href="https://www.hootsuite.com/pages/digital-trends-2021">
                                About 8% of the total time spent on mobiles is
                                spent on browser surfing
                            </a>
                            . Hence, with the right solutions, your reach is
                            also enhanced when you harness the power of
                            web-based applications as they require less
                            processing resources from older devices which means
                            more people can utilize your product without having
                            to worry about their device specifications.
                        </p>
                    </div>
                </div>
                <p>
                    Whether it’s customer loyalty, brand visibility, or
                    increased customer reach, app development is the one-stop
                    solution to all your business-based problems. Mobile
                    utilization has risen in the past few years and is set to
                    reach new heights in the coming times. In the year 2020, the
                    average person spent more than 4 hours on their mobile
                    phones pointing towards a drastic increase of around 20%
                    over the year 2019. With all number pointing towards the
                    growing use of mobile phones and all internet-connected
                    devices it has now become vital that you grow your business
                    and reach your strategic goals using application
                    development.
                </p>
            </Div>
        );
    }
}

export default AppDevelopment;
