import React, { Component } from "react";
import styled from "styled-components";
import headerPng from "./../Assests/header.png";
import headerLogoPng from "./../Assests/headerLogo.png";
import K from "./../K";
import readMorePng from "./../Assests/readMore.png";
import "./../App.css";

const Div = styled.div`
    display: flex;
    text-align: left;
    justify-content: space-between;
    padding-top: 80px;
    width: 100%;
    margin-bottom: 50px;
    div {
        margin-top: auto;
        margin-bottom: auto;
        width: 500px;
        margin-left: 100px;
        img {
            height: 2rem;
        }
        h1 {
            font-size: 1.6rem;
        }
    }
    .headerImg {
        height: 30rem;
    }
    @media (max-width: 960px) {
        display: inline;
        .headerImg {
            height: 15rem;
            width: 100%;
        }
        div {
            text-align: center;
            margin-left: 0;
            width: auto;
            padding: 10px;
            padding-top: 80px;
        }
    }
`;
class Header extends Component {
    render() {
        return (
            <Div>
                {/* <div>
                    <h1>DijkstraLabs</h1>
                    <h3>
                        Transforming Your Ideas Into Revolutionary Products And
                        Services
                    </h3>
                    <p>
                        We believe in delivering cutting-edge and next-gen
                        solutions to help you drive and lead the
                        software-powered digital landscape. From your
                        imagination to reality and then delivery, we are
                        committed to doing what we do best - bridging the gap
                        between you and your next great milestone.
                    </p>
                </div>
                <div className="readMore">
                    <h1>Read more</h1>
                    <img src={readMorePng} />
                </div> */}
                <div>
                    <img src={headerLogoPng} />
                    <h1>
                        Transforming Your Ideas Into Revolutionary Products And
                        Services
                    </h1>

                    <p>
                        We believe in delivering cutting-edge and next-gen
                        solutions to help you drive and lead the
                        software-powered digital landscape. From your
                        imagination to reality and then delivery, we are
                        committed to doing what we do best - bridging the gap
                        between you and your next great milestone.
                    </p>
                </div>
                <img src={headerPng} className="headerImg" />
            </Div>
        );
    }
}

export default Header;
