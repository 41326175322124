import React, { Component } from "react";
import styled from "styled-components";
import K from "../K";

const Div = styled.div`
    padding-top: 80px;
    padding-left: 100px;
    padding-right: 100px;
    text-align: left;

    h1 {
        span {
            color: ${K.Theme.secondary2};
        }
    }

    p {
        span {
            color: ${K.Theme.secondary2};
            font-weight: bolder;
        }
    }

    div {
        padding-left: 20px;
        div {
            p {
                padding-left: 20px;
            }
        }
    }

    .technologies {
        display: flex;
        flex-direction: row;
        img {
            height: 50px;
            width: 50px;
            margin: 5px;
        }
    }

    @media (max-width: 960px) {
        padding-left: 20px;
        padding-right: 20px;
    }
`;

class UXUIDesign extends Component {
    state = {
        images: [],
    };

    importAll = (r) => {
        return r.keys().map(r);
    };

    componentDidMount() {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
        const images = this.importAll(
            require.context(
                "./../Assests/Services/UI UX",
                false,
                /\.(png|jpe?g|svg)$/
            )
        );
        console.log(images);
        this.setState({ images: images });
    }
    render() {
        return (
            <Div>
                <h1>
                    <span>U</span>I – UX Design Services
                </h1>
                {/* <h2>Technologies</h2> */}
                <div className="technologies">
                    {this.state.images.map((image) => {
                        return <img src={image.default}></img>;
                    })}
                </div>
                <p>
                    <span>N</span>owadays it is essential to maintain a digital
                    presence to thrive in the fast-paced world of online-centric
                    business, but maintaining a digital presence is not nearly
                    enough. Your digital presence, whether it is an application
                    of a website, depends on quick, efficient, and responsive
                    interaction with the user; it’s all about the consumer and
                    their experience nowadays. So how does one hook in customers
                    and ensure a flawless experience for the customer base? The
                    answer lies in the often neglected and overseen aspects of
                    UI and UX design.
                </p>
                <p>
                    UX (User Experience) development and design is the effort
                    devoted to enhancing the utilization experience of the user.
                    It revolves around ensuring that the user completes the
                    objective they set in mind when they opened your application
                    or website.
                </p>
                <p>
                    UI (User Interface) design requires a skilled team at hand
                    to scale up the presentation and ensure smooth interactivity
                    with the application. From buttons, panes, scrolling
                    experience, and all visual elements on the screen all lie
                    under the umbrella of effective UI design.
                </p>
                <p>
                    The primary goals of both UI and UX design are centered on
                    increasing sales and revenue by bringing in traffic and
                    ensuring that traffic that comes on board stays. Recent
                    research by experts at{" "}
                    <a href="https://www.toptal.com/designers/ux/ux-statistics-insights-infographic">
                        Toptal
                    </a>{" "}
                    revealed that 88% of the users are hesitant to return to a
                    site after a bad usability experience while 90% reported
                    that they halted the utilization of an app due to sub-par
                    performance.
                </p>
                <p>
                    So, does evidence suggest that an enhanced UI and UX
                    experience bring in necessary traffic and revenue? The facts
                    speak for themselves. A survey conducted by Digital
                    Intelligence Briefing revealed that a massive{" "}
                    <a href="https://techjury.net/blog/user-experience-stats/#gref">
                        74% of the total visitors are likely to reuse a website
                    </a>{" "}
                    if it has good mobile UX. Similarly, research efforts
                    conducted by{" "}
                    <a href="https://techjury.net/blog/user-experience-stats/#gref">
                        Youpicon
                    </a>{" "}
                    revealed that every dollar ($1) spent on UI has the
                    potential to bring back 100% return on investment (ROI).
                </p>
                <p>
                    Luckily, DijkstraLabs has the team and expertise to cater to all
                    your UI and UX development needs. Our teams are experts in
                    not only developing the best UX but also in the utilization
                    of the best tools out there to enhance the UX; Figma and
                    Adobe XD are the front runners here at DijkstraLabs.
                </p>
                <p>
                    Our teams also specialize in Adobe Photoshop and Illustrator
                    to deliver the best quality graphics using the most modern
                    and stable design products out there. At DijkstraLabs we follow up
                    with the latest design principles so that your product
                    always stands out from the rest.
                </p>
                <p>DijkstraLabs specializes in delivering the best</p>

                <div>
                    <div>
                        <h3>1. UI/UX Web Design Services</h3>
                        <p>
                            Our team is certified and experienced in designing
                            engaging and interactive interfaces for
                            yourweb-based products so you can double down on
                            revenue and together we can achieve our milestones
                            quicker.
                        </p>
                    </div>
                    <div>
                        <h3>2. Cross-Platform Experience Design Services</h3>
                        <p>
                            We’re experts in implementing the ‘Universal Design
                            Approach’ that works for all platforms and delivers
                            the same flawless experience regardless of the
                            platform it is operating on.
                        </p>
                    </div>
                    <div>
                        <h3>3. Mobile UI/UX Design Services</h3>
                        <p>
                            With more mobile devices registering than ever
                            before, online purchases via mobile experience are
                            at an all-time high. We take pride in having the
                            most experienced and skilled team to design and
                            deliver digital products optimized for mobile
                            viewing and utilization.
                        </p>
                    </div>
                    <div>
                        <h3>4. UI/UX Design Consultancy Services</h3>
                        <p>
                            Our team of experts and professionals with years of
                            experience have insights to offer to you so you know
                            what you can change to thrive in a competitive
                            business environment.
                        </p>
                    </div>
                    <div>
                        <h3>5. Marketing Design Services</h3>
                        <p>
                            Our team of graphic designing experts ensures that
                            you have the right content to post for your social
                            media campaigns and business pitches. With the right
                            graphics, infographics, brochures, leaflets, app
                            store slides, and product advertisements, your
                            business is one step away from being the next big
                            thing.
                        </p>
                    </div>
                </div>
            </Div>
        );
    }
}

export default UXUIDesign;
