import React from "react";
import styled from "styled-components";
import K from "../K";
import { Link } from "react-router-dom";
import Burger from "./Burger";
import { useHistory } from "react-router-dom";
import logoPng from "./../Assests/logo.png";

const Nav = styled.nav`
    width: 100%;
    height: 75px;
    border-bottom: 2px solid ${K.Theme.primary};
    display: flex;
    justify-content: space-between;
    position: fixed;
    top: 0;
    background-color: ${K.Theme.primary};
    z-index: 1001;

    .logo {
        /* width: 15%; */
        top: 50%;
        height: 50%;
        padding-left: 100px;
        cursor: pointer;

        position: relative;
        top: 50%;
        transform: translateY(-50%);
    }

    .logoMobile {
        /* width: calc(100% - 3rem); */
        height: 100%;
        padding: 5px;
        display: none;
        cursor: pointer;
    }

    @media (max-width: 960px) {
        padding: 0;
        background-color: ${K.Theme.primary};
        /* position: relative; */

        .logoMobile {
            display: block;
        }

        .logo {
            padding-left: 10px;
        }
    }
`;

const Navbar = (props) => {
    const history = useHistory();

    return (
        <Nav>
            <Link to={K.Routes.Home}>
                <img src={logoPng} alt="" className="logo" />
            </Link>
            <Burger />
        </Nav>
    );
};

export default Navbar;
