import React, { Component } from 'react';
import styled from 'styled-components';
import K from './../K'
import servicesHeaderPng from './../Assests/servicesHeader.png'

const Div = styled.div `
    display: flex;
    text-align: left;
    justify-content: space-between;
    padding-top: 80px;
    width: 100%;
    margin-bottom: 50px;
    div {
        margin-top: auto;
        margin-bottom: auto;
        width: 500px;
        margin-left: 100px;
        img {
            height: 2rem;
        }
        h1 {
            color: ${K.Theme.secondary2}
        }
    }
    .headerImg {
        height: 30rem;
    }
    @media (max-width: 960px) {
            
        display:inline;
        .headerImg {
            height: 15rem;
            width: 100%;
        }
        div {
            text-align: center;
            margin-left: 0;
            width: auto;
            padding: 10px;
            padding-top: 80px;
        }
    }
`
class Header extends Component {
    render() {
        return (
            <Div>
                <div> 
                    <h1>Our Services</h1>
                    <h3>We are changing the world at a rapid pace.</h3>
                </div>
                <img src={servicesHeaderPng} className="headerImg"/>
            </Div>
        );
    }
}

export default Header;