import React, { Component } from "react";
import styled from "styled-components";
import K from "./../K";
import { Grid } from "@material-ui/core";
import mlPng from "./../Assests/ml.png";
import arrowPng from "./../Assests/arrow.png";
import moneyPng from "./../Assests/money.png";
import rubricPng from "./../Assests/rubric.png";

import { Link } from "react-router-dom";

const Div = styled.div`
    text-align: left;
    padding-left: 100px;
    padding-right: 100px;
    /* width:100%; */

    h1 {
        /* font-size: 2.5rem; */
        color: ${K.Theme.secondary2};
    }
    h2 {
        color: ${K.Theme.secondary2};
        /* margin-bottom: -10px; */
    }
    h3 {
        margin-top: -25px;
    }

    .headerItem {
        display: flex;
        background-color: ${K.Theme.primary2};
        margin-bottom: 50px;

        .mainImg {
            margin-right: 60px;
            margin-left: 50px;
            display: block;
            width: 40%;
        }
        div {
            position: relative;
            height: 100%;
            margin-left: 60px;
            .arrowImg {
                height: 2rem;
                width: 2rem;
                position: absolute;
                bottom: 0;
            }
        }
    }
    .otherItem {
        width: 45%;
        background-color: ${K.Theme.primary2};
        margin-bottom: 50px;
        .mainImg {
            display: block;
            width: 100%;
            margin-bottom: 10px;
        }
        .arrowImg {
            height: 2rem;
            width: 2rem;
        }
    }

    div {
        display: flex;
        flex-direction: row;
        text-align: left;
        div {
            text-align: left;
            display: flex;
            flex-direction: column;
            padding-left: 10px;
            padding-right: 10px;
        }
    }

    ol {
        text-align: left;
    }

    a {
        padding: 10px;
        text-decoration: none;
        span {
            font-size: 20px;
        }
        :hover {
            text-decoration: underline;
        }
    }

    @media (max-width: 960px) {
        padding-left: 30px;
        padding-right: 30px;
        text-align: center;
        div {
            flex-direction: column;
        }
        .headerItem {
            display: inline;
            margin-bottom: 10px;
            .mainImg {
                margin-right: auto;
                margin-left: auto;
                display: block;
                width: 100%;
            }
            div {
                margin-right: auto;
                margin-left: auto;
                height: auto;
                .arrowImg {
                    height: 2rem;
                    width: 2rem;
                    position: relative;
                    bottom: 0;
                }
            }
        }
        .otherItem {
            width: 100%;
            background-color: ${K.Theme.primary2};
            margin-bottom: 10px;
            .mainImg {
                display: block;
                width: 100%;
                margin-bottom: 10px;
            }
            .arrowImg {
                height: 2rem;
                width: 2rem;
            }
        }
    }
`;

class Services extends Component {
    render() {
        return (
            <Div id="services">
                <h1>Our Services</h1>
                <h3>Ready to help you create the impossible.</h3>
                <p>
                    With a workforce of industry veterans and implementation of
                    processes that are battle proven we provide 360° coverage to
                    cater to all your development and service needs.
                </p>
                <div>
                    <div>
                        <Link to={K.Routes.UXUIDesign}>
                            <span>›</span> UX/UI Design
                        </Link>
                        <Link to={K.Routes.WebDevelopment}>
                            <span>›</span> Web Development
                        </Link>
                        <Link to={K.Routes.AppDevelopment}>
                            <span>›</span> App Development
                        </Link>
                        <Link to={K.Routes.CloudSolutions}>
                            <span>›</span> Cloud Solutions
                        </Link>
                    </div>
                    <div>
                        <Link to={K.Routes.NLPAndNLU}>
                            <span>›</span> NLP and NLU
                        </Link>
                        <Link to={K.Routes.DataScience}>
                            <span>›</span> Data Science
                        </Link>
                        <Link to={K.Routes.BigDataSolutions}>
                            <span>›</span> Big Data Solutions
                        </Link>
                        <Link to={K.Routes.DataEngineering}>
                            <span>›</span> Data Engineering
                        </Link>
                    </div>
                    <div>
                        <Link to={K.Routes.RealtimeAnalytics}>
                            <span>›</span> Realtime Analytics
                        </Link>
                        <Link to={K.Routes.BlockchainSolutions}>
                            <span>›</span> Blockchain Solutions
                        </Link>
                    </div>
                </div>
                {/* <h2>Featured Services</h2> */}
                {/* <Grid container justify="space-between" spacing={5}> 
                    <Grid item className="headerItem">
                        <div>
                            <h4>Using ML to predict Student depression behavious</h4>
                            <p>
                                We used some voodoo magic machine learning algorithms to try to understadn why
                                students are such big cunts. Our GPUs burnt out before we could make any sense.
                            </p>
                            <img src={arrowPng} className="arrowImg"/>
                        </div>
                        <img src={mlPng} className="mainImg"/>
                    </Grid>
                    <Grid item className="otherItem">
                        <h4>Well this is some Magic app we made</h4>
                        <p>
                            It solves Rubix cubes.
                        </p>
                        <img src={rubricPng} className="mainImg"/>
                        <img src={arrowPng} className="arrowImg"/>
                    </Grid>
                    <Grid item className="otherItem">
                        <h4>Money Money Money</h4>
                        <p>
                            Big Bucks App.
                        </p>
                        <img src={moneyPng} className="mainImg"/>
                        <img src={arrowPng} className="arrowImg"/>
                    </Grid>
                </Grid> */}
            </Div>
        );
    }
}

export default Services;
