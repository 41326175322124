import React, { Component } from 'react';
import styled from 'styled-components';
import K from '../K'
import { Grid } from '@material-ui/core';
import mlPng from './../Assests/ml.png'
import arrowPng from './../Assests/arrow.png'
import moneyPng from './../Assests/money.png'
import rubricPng from './../Assests/rubric.png'

const Div = styled.div`

    text-align:left;
    padding-left: 100px;
    padding-right: 100px;
    /* width:100%; */
    
    
    h1 {
        /* font-size: 2.5rem; */
        color: ${K.Theme.secondary2};
    }
    h2 {
        color: ${K.Theme.secondary2};
        /* margin-bottom: -10px; */
    }
    h3 {
        margin-top: -25px;
    }

    .headerItem {
        display: flex;
        background-color: ${K.Theme.primary2};
        margin-bottom: 50px;
        .mainImg {
            margin-right:60px;
            margin-left: 50px;
            display:block;
            width: 40%;
        }
        div {
            position: relative;
            height: 100%;
            margin-left: 60px;
            margin-bottom: 50px;Services
            .arrowImg {
                height: 2rem;
                width: 2rem;
                position: absolute;
                bottom: 0;
            }
        }
    }
    .otherItem {
        width: 45%;
        background-color: ${K.Theme.primary2};
        .mainImg {
            display:block;
            width: 100%;
            margin-bottom: 10px;
        }
        .arrowImg {
            height: 2rem;
            width: 2rem;
        }
    }

    @media (max-width: 960px) {
        padding-left: 30px;
        padding-right: 30px;
        text-align: center;
        .headerItem {
            display: inline;
            margin-bottom: 10px;
            .mainImg {
                margin-right:auto;
                margin-left: auto;
                display:block;
                width: 100%;
            }
            div {
                margin-right: auto;
                margin-left: auto;
                height: auto;
                .arrowImg {
                    height: 2rem;
                    width: 2rem;
                    position: relative;
                    bottom: 0;
                }
            }
        }
        .otherItem {
            width: 100%;
            background-color: ${K.Theme.primary2};
            margin-bottom: 10px;
            .mainImg {
                display:block;
                width: 100%;
                margin-bottom: 10px;
            }
            .arrowImg {
                height: 2rem;
                width: 2rem;
            }
        }
    }
`

class Technologies extends Component {
    render() {
        return (
            <Div>
                <h1>Technologies</h1>
                <h3>We are changing the world at a rapid pace.</h3>
                <p>
                    With over 270,000 team members in almost 50 countries countries and partnerships with a broad 
                    spectrum of technology vendors, Capgemini has both the resources and expertise to react quickly 
                    to client needs — a key asset in a digital era when deployment needs to be measured in weeks rather than months.
                </p>
                <h2>Featured Technologies</h2>
                <Grid container justify="space-between" spacing={5}> 
                    <Grid item className="headerItem">
                        <div>
                            <h4>Using ML to predict Student depression behavious</h4>
                            <p>
                                We used some voodoo magic machine learning algorithms to try to understadn why
                                students are such big cunts. Our GPUs burnt out before we could make any sense.
                            </p>
                            <img src={arrowPng} className="arrowImg"/>
                        </div>
                        <img src={mlPng} className="mainImg"/>
                    </Grid>
                    <Grid item className="otherItem">
                        <h4>Well this is some Magic app we made</h4>
                        <p>
                            It solves Rubix cubes.
                        </p>
                        <img src={rubricPng} className="mainImg"/>
                        <img src={arrowPng} className="arrowImg"/>
                    </Grid>
                    <Grid item className="otherItem">
                        <h4>Money Money Money</h4>
                        <p>
                            Big Bucks App.
                        </p>
                        <img src={moneyPng} className="mainImg"/>
                        <img src={arrowPng} className="arrowImg"/>
                    </Grid>
                </Grid>
            </Div>
        );
    }
}

export default Technologies;