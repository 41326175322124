import React, { Component } from "react";
import styled from "styled-components";
import K from "../K";

const Div = styled.div`
    padding-top: 80px;
    padding-left: 100px;
    padding-right: 100px;
    text-align: left;

    h1 {
        span {
            color: ${K.Theme.secondary2};
        }
    }

    p {
        span {
            color: ${K.Theme.secondary2};
            font-weight: bolder;
        }
    }

    div {
        padding-left: 20px;
        div {
            p {
                padding-left: 20px;
            }
        }
    }
    .technologies {
        display: flex;
        flex-direction: row;
        img {
            height: 50px;
            width: 50px;
            margin: 5px;
        }
    }

    @media (max-width: 960px) {
        padding-left: 20px;
        padding-right: 20px;
    }
`;

class RealtimeAnalytics extends Component {
    state = {
        images: [],
    };

    importAll = (r) => {
        return r.keys().map(r);
    };

    componentDidMount() {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
        const images = this.importAll(
            require.context(
                "./../Assests/Services/Realtime Analytics",
                false,
                /\.(png|jpe?g|svg)$/
            )
        );
        console.log(images);
        this.setState({ images: images });
    }
    render() {
        return (
            <Div>
                <h1>
                    <span>R</span>eal-Time Analytics
                </h1>
                {/* <h2>Technologies</h2> */}
                <div className="technologies">
                    {this.state.images.map((image) => {
                        return <img src={image.default}></img>;
                    })}
                </div>
                <p>
                    Businesses are creating more data than ever before and the
                    data comprehension systems at play in most organizations are
                    not fast enough. For you to get the most out of your system
                    you need solutions that can analyze the data as soon as it
                    enters the system; that is when we can get the most insight.
                    This is where Real-Time analytic solutions come into play,
                    with the right team by your side you no longer have to rely
                    on historic data to see patterns and can instantly extract
                    valuable insights from your data streams.
                </p>
                <p>
                    Where can you implement real-time analytics? The
                    possibilities are endless depending on what domain you
                    operate in, but real-time analytics could help you in
                </p>
                <div>
                    <div>
                        <h3>
                            1. Increasing Operational Efficiency and Enhanced
                            Decision Making
                        </h3>
                        <p>
                            Business agility refers to your strategic and
                            tactical goals. With multiple teams acting in
                            multiple domains in your operations you can enable
                            them to make better and more data-backed decisions
                            which ensure a faster and safer change in your
                            business environment.
                        </p>
                    </div>
                    <div>
                        <h3>2. Dealing with Operational Issues</h3>
                        <p>
                            With real-time reports indicating statistics such as
                            supply and demand, market satisfaction, employee
                            performance, and many more you have what it takes to
                            make the decisions that impact your business the
                            most. With real-time analytics, you can reduce
                            downtimes, shortages, and employee layoffs easily!
                        </p>
                    </div>
                    <div>
                        <h3>3. Acting on Ever-Changing Market Trends</h3>
                        <p>
                            As some operate in industries that are highly
                            sensitive to market fluctuations real-time analysis
                            is essential for thriving in such anenvironment.
                            With real-time analysis by your side, you can adjust
                            your pricing, values and supply chain according to
                            the market situation, so you never have to face a
                            loss ever again.
                        </p>
                    </div>
                    <div>
                        <h3>4. Personalizing the Marketing Experience</h3>
                        <p>
                            Real-time analytics enable you to make your customer
                            base feel more special and enhance their user
                            experience. With real-time bots and customer
                            engagement methods, you can now customize offers and
                            messages specifically for the customer you are
                            targeting.
                        </p>
                    </div>
                </div>
                <p>
                    You could also integrate RTA in your business in the form of
                    a real-time credit scoring mechanism that can help you
                    re-evaluate finances and whether or not you should extend
                    credits, establishing a better CRM (Customer Relationship
                    Management) which is bound to lead to better satisfaction
                    and revenue input.
                </p>
                <p>
                    Real-time analytics have shown a massive increase in
                    customer response and revenue generation. A report by Forbes
                    puts forward that a massive percentage of about{" "}
                    <a href="https://www.forbes.com/sites/louiscolumbus/2018/07/08/how-to-improve-customer-experiences-with-real-time-analytics/?sh=79a9da286e82">
                        58% of organizations saw an increase in customer
                        retention and loyalty
                    </a>{" "}
                    after integrating real-time analytics into their operations.
                    Another report suggests that a{" "}
                    <a href="https://www.forbes.com/sites/louiscolumbus/2018/07/08/how-to-improve-customer-experiences-with-real-time-analytics/?sh=79a9da286e82">
                        possible 44% percent increase is possible by integrating
                        real-time analytics into your business operations
                    </a>{" "}
                    but don't just take our word for it, other leading business
                    owners, much like you, about{" "}
                    <a href="https://www.forbes.com/sites/louiscolumbus/2018/07/08/how-to-improve-customer-experiences-with-real-time-analytics/?sh=79a9da286e82">
                        60% of them believe that real-time analysis of data is
                        extremely efficient and important
                    </a>
                    .
                </p>
                <p>
                    Real-time analytics have shown a massive increase in
                    customer response and revenue generation. A report by Forbes
                    puts forward that a massive percentage of about 58% of
                    organizations saw an increase in customer retention and
                    loyalty after integrating real-time analytics into their
                    operations. Another report suggests that a possible 44%
                    percent increase is possible by integrating real-time
                    analytics into your business operations but don't just take
                    our word for it, other leading business owners, much like
                    you, about60% of them believe that real-time analysis of
                    data is extremely efficient and important.
                </p>
                <div>
                    <div>
                        <h3>1. Modern Data Warehousing</h3>
                        <p>
                            We offer data warehousing solutions for
                            organizations and businesses that include data lake
                            deployment, hybrid cloud establishment, big query
                            cloud implementation, or even the public cloud. We
                            are the one-stop solution to all your warehousing
                            needs.
                        </p>
                    </div>
                    <div>
                        <h3>2. Data Analytics</h3>
                        <p>
                            Using modern tools such as Data Studio, Data Lab,
                            Cloud App Engine we provide solutions to enable you
                            to host on-premises or even self-service analysis
                            and query execution so you can understand what data
                            says about you and your business!
                        </p>
                    </div>
                    <div>
                        <h3>3. Consultation Services </h3>
                        <p>
                            We are determined to help you narrow down and
                            identify the scope; suggesting the best tools and
                            provide you with our expertise in all things data.
                            Our experts promise to always be your side and guide
                            you when it matters the most.
                        </p>
                    </div>
                    <div>
                        <h3>4. Integration Services</h3>
                        <p>
                            With the help of our experts, you can now integrate
                            real-time analytics-based solutions into your
                            marketing solutions. From social media scanning to
                            the development of custom CRM software that has
                            in-built reporting systems. We have got you covered!
                        </p>
                    </div>
                </div>
            </Div>
        );
    }
}

export default RealtimeAnalytics;
