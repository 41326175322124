import React, { Component } from "react";

import styled from "styled-components";
import K from "../K";

const Div = styled.div`
    padding-top: 80px;
    text-align: left;
    padding-left: 100px;
    padding-right: 100px;
    h1 {
        color: ${K.Theme.secondary2};
    }
    div {
        padding: 0;
        padding: 1px 10px;
        background-color: #f6f0fb;
        border-color: ${K.Theme.secondary2};
        font-style: italic;
        p {
            border-left-style: solid;
            padding: 10px;
        }
    }

    @media (max-width: 960px) {
        padding: 0px 10px;
        padding-top: 80px;
    }
`;

class Header extends Component {
    render() {
        return (
            <Div>
                <h1>About Us</h1>
                <p>We are DijkstraLabs </p>
                <div>
                    Spearheading the charge to innovate, transform and push
                    forward for a better tomorrow.
                </div>
                <p>
                    Who are we? We're a team of dedicated go-getters, tech
                    enthusiasts and expert strategists brought together by our
                    will and ambition to create, to revolutionize, to do our
                    part, and help you do yours. We're leading the movement to
                    bring the most radical and cutting-edge solutions that
                    enable you to provide answers for tomorrow's problems today,
                    all while having a global impact and a generous ROI so you
                    never have to hit the brakes!
                </p>
            </Div>
        );
    }
}

export default Header;
