import React, { Component } from "react";
import styled from "styled-components";
import K from "../K";

const Div = styled.div`
    padding-top: 80px;
    padding-left: 100px;
    padding-right: 100px;
    text-align: left;

    h1 {
        span {
            color: ${K.Theme.secondary2};
        }
    }

    div {
        padding-left: 20px;
        div {
            p {
                padding-left: 20px;
            }
        }
    }

    .technologies {
        display: flex;
        flex-direction: row;
        img {
            height: 50px;
            width: 50px;
            margin: 5px;
        }
    }

    @media (max-width: 960px) {
        padding-left: 20px;
        padding-right: 20px;
    }
`;

class BigDataSolutions extends Component {
    state = {
        images: [],
    };

    importAll = (r) => {
        return r.keys().map(r);
    };

    componentDidMount() {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
        const images = this.importAll(
            require.context(
                "./../Assests/Services/Big Data Solutions",
                false,
                /\.(png|jpe?g|svg)$/
            )
        );
        console.log(images);
        this.setState({ images: images });
    }
    render() {
        return (
            <Div>
                <h1>
                    <span>B</span>ig Data Solutions
                </h1>
                {/* <h2>Technologies</h2> */}
                <div className="technologies">
                    {this.state.images.map((image) => {
                        return <img src={image.default}></img>;
                    })}
                </div>
                <p>
                    Big data is the name of structured or unstructured storage
                    of data. Businesses generate a colossal amount of data every
                    day but in the 21st century, it is not the amount of data
                    that matters but how an organization utilizes this data.
                </p>
                <p>
                    Big data is dependent on the volume, velocity of data
                    processing, and the variety of data available that may be
                    structured or unstructured. This collection of data requires
                    enhanced information processing so that it can lead to
                    valuable insight generation and decision making.
                </p>
                <p>
                    With the right big data solutions by your side, you can
                    avail benefits that include
                </p>
                <div>
                    <div>
                        <h3>1. Cost Slashing</h3>
                        <p>
                            Big data frameworks such as Hadoop allows clustering
                            and the storage of vast amounts of data, so you do
                            not have to spend fortunes on purchasing physical
                            components yourself. All your information is kept
                            safe, and your data is assured to be looked after
                            according to the highest set regulatory standards.
                        </p>
                    </div>
                    <div>
                        <h3>2. Enhanced Decision Making</h3>
                        <p>
                            With large data sets and the power of cloud-based
                            processing backing, you can now process a tremendous
                            amount of data and take actions based on what the
                            data tells you.
                        </p>
                    </div>
                    <div>
                        <h3>3. Increased Research and Development</h3>
                        <p>
                            Customer satisfaction ratings along with analytics
                            monitoring customer needs, you can now shift your
                            development efforts to what matters the most so you
                            can come out with the best product possible.
                        </p>
                    </div>
                    <div>
                        <h3>4. Risk Analysis</h3>
                        <p>
                            With the day-to-day operations running at full
                            throttle there is more to a company&#39;s success
                            than just the obvious. Social and economic factors
                            play a huge role in the success. With big data
                            allowing us to utilize the power of predictive
                            analysis, you can scan social media feeds and
                            anticipate the reaction of your customers.
                        </p>
                    </div>
                </div>
                <p>
                    Don’t just take out word for it, the statistics talk for
                    themselves. A recent report suggests that{" "}
                    <a href="https://www.statista.com/statistics/551501/worldwide-big-data-business-analytics-revenue/">
                        big data spending stood at 168.8 billion in 2018 and is
                        projected to hit numbers as high as 274.3 billion
                        dollars.
                    </a>
                    .
                </p>
                <p>
                    <a href="https://hostingtribunal.com/blog/big-data-stats/#gref">
                        reported growth of 20.8% annually
                    </a>
                    . Statista also concluded that countries with high big data
                    adoption rates have{" "}
                    <a href="https://hostingtribunal.com/blog/big-data-stats/#gref">
                        reported growth of 20.8% annually
                    </a>
                    . with numbers projected to plummet in the coming times.
                    These facts and figures indicate that the future is Big
                    Data, and your focus should be aligned with the world.
                </p>
                <p>
                    The team at DijkstraLabs has harnessed the power of big data and
                    after years of experience has the confidence to hold your
                    hand and lead you into the future. Our team specializes in
                    the latest and most trusted big data frameworks so that you
                    can choose from a variety of options and we can meet your
                    requirements.
                </p>
                <div>
                    <div>
                        <h3>1. Apache Hadoop</h3>
                        <p>
                            While Hadoop is used for all-purpose data storage
                            and secure containment our team knows its true
                            potential and is ready to serve your need of
                            scalable, reliable, and distributed calculation
                            needs using this framework. We plan to use Hadoop
                            when you need huge storage and swift data processing
                            for your solutions.
                        </p>
                    </div>
                    <div>
                        <h3>2. Spark</h3>
                        <p>
                            Hadoop and Spark work hand in hand sometimes. While
                            Hadoop stores data Spark lends a helping hand and
                            performs all tricky operations to provide you with
                            the most accurate analysis. Spark is known for its
                            high performance and integrated fail-safe systems.
                        </p>
                    </div>
                    <div>
                        <h3>3. Hive</h3>
                        <p>
                            Hive capitalizes on Hadoop and allows us to
                            manipulate petabytes of your data using SQL. With
                            less reliance on Java code, we can now use Hive to
                            push out your products faster than ever before!
                        </p>
                    </div>
                </div>
                <p>
                    DijkstraLabs is the one-stop solution house for all your big data
                    needs and specializes in other frameworks as well such as
                    Storm, Samza, and Flink. With mastery in an array of
                    frameworks and methodologies, we are proud to offer services
                    in the following forms,
                </p>
                <div>
                    <div>
                        <h3>1. Personalized Data Analytics</h3>
                        <p>
                            We provide an extensive tool kit for you so that
                            your data can be converted into actionable insight
                            and you can act on it.
                        </p>
                    </div>
                    <div>
                        <h3>2. ETL Services (Extract. Transform. Load)</h3>
                        <p>
                            With data streams being generated from multiple
                            sources our solutions make business analysis easier
                            than ever before. With our support, you can now
                            collect data from multiple sources and use it to
                            bring about necessary change.
                        </p>
                    </div>
                    <div>
                        <h3>3. Ad Hoc Reporting</h3>
                        <p>
                            Our sophisticated algorithms can now help you use
                            business intelligence to evaluate monthly or even
                            annual performance figures and determine whether an
                            action is necessary and if so, where.
                        </p>
                    </div>
                    <div>
                        <h3>4. Big Data Infrastructure Set Up and Support</h3>
                        <p>
                            We are eager to set up and support your big data
                            efforts. With us by your side, we guarantee the best
                            utilization of your resources.
                        </p>
                    </div>
                    <div>
                        <h3>5. Performance Evaluation Solutions</h3>
                        <p>
                            These solutions can help you evaluate the
                            employees&#39; performance, their productivity, and
                            how you can ensure that their goals align with the
                            organization’s to reach full potential.
                        </p>
                    </div>
                </div>
            </Div>
        );
    }
}

export default BigDataSolutions;
