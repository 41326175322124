import React, { Component } from 'react';
import styled from 'styled-components';
import readMorePng from './../Assests/readMore.png'
import K from './../K'

const Div = styled.div`
    h1 {
        color: ${K.Theme.secondary3};
        font-size: 1.5rem;
    }
`

class ReadMore extends Component {
    render() {
        return (
            <Div>
                <h1>Read more</h1>
                <img src={readMorePng} />
            </Div>
        );
    }
}

export default ReadMore;