import React, { Component } from "react";
import styled from "styled-components";
import K from "../K";

const Div = styled.div`
    padding-top: 80px;
    padding-left: 100px;
    padding-right: 100px;
    text-align: left;

    h1 {
        span {
            color: ${K.Theme.secondary2};
        }
    }

    p {
        span {
            color: ${K.Theme.secondary2};
            font-weight: bolder;
        }
    }

    div {
        padding-left: 20px;
        div {
            p {
                padding-left: 20px;
            }
        }
    }

    .technologies {
        display: flex;
        flex-direction: row;
        img {
            height: 50px;
            width: 50px;
            margin: 5px;
        }
    }

    @media (max-width: 960px) {
        padding-left: 20px;
        padding-right: 20px;
    }
`;

class BlockchainSolutions extends Component {
    state = {
        images: [],
    };

    importAll = (r) => {
        return r.keys().map(r);
    };

    componentDidMount() {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
        const images = this.importAll(
            require.context(
                "./../Assests/Services/Block CHain",
                false,
                /\.(png|jpe?g|svg)$/
            )
        );
        console.log(images);
        this.setState({ images: images });
    }
    render() {
        return (
            <Div>
                <h1>
                    <span>B</span>lockchain
                </h1>
                {/* <h2>Technologies</h2> */}
                <div className="technologies">
                    {this.state.images.map((image) => {
                        return <img src={image.default}></img>;
                    })}
                </div>
                <p>
                    DijkstraLabs is committed to providing our clients
                    with tailored blockchain solutions. Using the digital
                    design, modern crypto architectures, and deployment services
                    we serve our clients with the best market-ready solutions.
                </p>
                <p>
                    Blockchain is the storing of information on a digital ledger
                    shared across a network which makes it highly secure,
                    efficient, and prone to attacks. Blockchain is making its
                    way through the digital landscape and is slowly becoming the
                    business focus for many organizations. Apart from the fact
                    that Blockchain is highly secure, Blockchain tech is known
                    to reduce operation costs by a huge margin as reported by{" "}
                    <a href="https://www.mckinsey.com/business-functions/mckinsey-digital/our-insights/blockchain-beyond-the-hype-what-is-the-strategic-business-value#">
                        Mckinsey and Co.
                    </a>
                </p>
                <p>Blockchain Technology provides other benefits as well,</p>
                <div>
                    <div>
                        <h3>1. Goodbye Middleman</h3>
                        <p>
                            With Blockchain tech integrated into your
                            operations, you no longer have to bear the costs of
                            having a middleman employed to keep track of your
                            operations and transactions.
                        </p>
                    </div>
                    <div>
                        <h3>2. Robust Data Storage</h3>
                        <p>
                            With Blockchain’s ‘append only’ property, any sort
                            of data manipulation is impossible. Your information
                            has never been this secure!
                        </p>
                    </div>
                    <div>
                        <h3>3. Transparency</h3>
                        <p>
                            With every block (node) keeping track of every
                            transaction that is being made, all participants
                            have insight into the network.
                        </p>
                    </div>
                </div>
                <p>
                    Why do you need to invest in blockchain solutions? Well, by
                    the year{" "}
                    <a href="https://techjury.net/blog/blockchain-statistics/">
                        2022 global spending on Blockchain solutions is
                        projected to hit the 12 billion dollar mark
                    </a>
                    . With others moving forward the team at DijkstraLabs lends you a
                    hand so together we can achieve tomorrow’s goals today! .
                </p>
                <p>
                    With Blockchain tech projected to take over the world, BaaS
                    (Blockchain As A Service) is on a rise. DijkstraLabs has expertise
                    in the integration of services that include,
                </p>
                <div>
                    <p>
                        1. Amazon Managed Blockchain <br />
                        2. Azure Blockchain Work Bench <br />
                        3. Oracle Blockchain Cloud <br />
                        4. IBM Blockchain
                    </p>
                </div>
                <p>
                    Our services are not limited to merely integration. Our team
                    has been a front runner in the Blockchain world since the
                    beginning and is eager to help you solve your problems using
                    Blockchain.
                </p>
                <div>
                    <div>
                        <h3>1. Wallet Development</h3>
                        <p>
                            Our team utilizes the best safety and development
                            standards to deliver the best exchanges and wallets,
                            so you never have to face a huddle in your
                            transactions.
                        </p>
                    </div>
                    <div>
                        <h3>2. App Development</h3>
                        <p>
                            Our decentralized application development ensures
                            that your data is never manipulated or tampered
                            with. We promise to keep all your information and
                            records SAFE.
                        </p>
                    </div>
                    <div>
                        <h3>3. Testing and Migration Services</h3>
                        <p>
                            Have existing businesses without Blockchain
                            integrated? No problem. Our team is eager to not
                            only set up your Blockchain infrastructure but also
                            test it for you so that you never have to worry
                            about security ever again.
                        </p>
                    </div>
                    <div>
                        <h3>4. Blockchain Consulting</h3>
                        <p>
                            The team of experts at DijkstraLabs is always ready to
                            analyze your business operations and recommend the
                            best possible Blockchain-based solution for your
                            business.
                        </p>
                    </div>
                    <div>
                        <h3>5. Smart Contract Services</h3>
                        <p>
                            With our optimized and secure smart contracts at
                            your disposal, you will never have to worry about
                            closing a deal. Let our systems take care of
                            controlling, executing all actions according to the
                            terms you have agreed upon in the agreement.
                        </p>
                    </div>
                </div>
            </Div>
        );
    }
}

export default BlockchainSolutions;
